import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction",
    },
    {
      id: "why-choose-zzeeh",
      label: "Why Choose Zzeeh Weddings?",
    },
    {
      id: "services-offered",
      label: "Services Offered by Zzeeh Weddings",
    },
    {
      id: "top-wedding-venues",
      label: "Top Wedding Venues Curated by Zzeeh Weddings",
    },
    {
      id: "client-testimonials",
      label: "Client Testimonials: Real Experiences",
    },
    {
      id: "budget-friendly-packages",
      label: "Budget-Friendly Packages",
    },
    {
      id: "cultural-elements",
      label: "How Zzeeh Weddings Incorporates Cultural Elements",
    },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Expertise in Hindu Weddings",
      body: "Zzeeh Weddings specializes in planning traditional Hindu weddings, from the intricate Mehendi and Sangeet ceremonies to the grand final day. As one of the most sought-after wedding organizers, they ensure that every ritual is performed with authenticity, combining modern-day convenience with timeless customs.",
    },
    {
      header: "Customized Wedding Themes",
      body: "Each couple is unique, and so should be their wedding. Zzeeh offers personalized themes, blending vibrant Indian culture with the latest design trends. Whether you’re looking for a royal palace theme or a simple yet elegant wedding set up, their team can deliver exactly what you envision.",
    },
    {
      header: "Unique Approach to Wedding Traditions",
      body: "Zzeeh is known for its ability to creatively integrate Hindu rituals with contemporary elements, making them a favorite among couples searching for wedding event planners near them. They ensure traditions are not just followed but celebrated in grand style.",
    },
  ];

  const list2 = [
    {
      header: "Pre-Wedding Services",
      body: "Planning begins months before the big day. Zzeeh Weddings offers services like venue selection, guest list management, pre-wedding photoshoots, Mehendi, and Sangeet planning. They are the go-to wedding event management team that takes care of every detail.",
    },
    {
      header: "Wedding Day Services",
      body: "On the big day, Zzeeh ensures everything runs smoothly. From managing the wedding set up to catering and guest coordination, every aspect is executed to perfection. They are true professional wedding planners who ensure that the couple enjoys their day stress-free.",
    },
    {
      header: "Post-Wedding Services",
      body: "Post-wedding receptions, special send-offs, and even honeymoon planning are part of Zzeeh’s repertoire. With their reputation as top wedding planners, they ensure that every stage of the celebration is memorable.",
    },
  ];

  const list3 = [
    {
      header: "Temple Weddings",
      body: "Zzeeh Weddings specializes in intimate, sacred ceremonies at beautiful temples, making them the ideal marriage planners for traditional Hindu weddings.",
    },
    {
      header: "Resort Weddings",
      body: "For those who want a luxurious and serene backdrop, Zzeeh curates the best resort venues in Bangalore, ensuring a magical setting for your special day.",
    },
    {
      header: "Palace Weddings",
      body: "Want to feel like royalty? Zzeeh is renowned for organizing grand palace weddings with regal decor and intricate detailing, perfect for those seeking a best wedding planner for a lavish affair.",
    },
  ];

  const list4 = [
    {
      header: "Comprehensive Packages",
      body: "Zzeeh Weddings offers all-inclusive packages, making them one of the most versatile wedding event planners in Bangalore. These packages are customizable to fit your budget and preferences.",
    },
    {
      header: "Flexible Pricing Options",
      body: "Whether you’re seeking a luxurious wedding or one on a tight budget, Zzeeh provides solutions as both affordable wedding planners near me and premier luxury wedding planners.",
    },
  ];

  const list5 = [
    {
      header: "Traditional Rituals and Customs",
      body: "Zzeeh Weddings ensures that all Hindu rituals, from the Varmala to the Kanyadaan, are executed with cultural authenticity. This makes them an excellent choice for those in search of Hindu wedding planners.",
    },
    {
      header: "Décor that Reflects Hindu Heritage",
      body: "The team specializes in decor that reflects Hindu heritage, including intricate rangoli designs, floral mandaps, and more. Their attention to detail cements their position as top wedding planners in Bangalore.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Best Hindu Wedding Planners in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/8FXTSwpb_Go?si=EUCy6YmbjdT_ljEB"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Planning a wedding is one of the most joyous yet challenging tasks. In
          India, weddings hold a special cultural significance, especially in
          Hindu traditions, where intricate rituals and vibrant customs are
          celebrated over several days. Among the many wedding planners
          available in Bangalore,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          stands out as one of the best for{" "}
          <span className="font-semibold">Hindu weddings planners</span>.
        </p>
        <p>
          In this blog, we explore the various offerings and expertise of{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , showcasing why they are a perfect fit for your special day.
        </p>
      </section>

      <section id="why-choose-zzeeh" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings?
        </h1>
        <DynamicList listType="list-[lower-alpha]" data={list1} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/neha-and-santhosh-wedding-moments.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="services-offered" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Services Offered by Zzeeh Weddings
        </h1>
        <DynamicList newLine listType="list-[lower-alpha]" data={list2} />
      </section>

      <section id="top-wedding-venues" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Wedding Venues Curated by Zzeeh Weddings
        </h1>
        <DynamicList newLine listType="list-[lower-alpha]" data={list3} />
      </section>

      <section id="client-testimonials" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Client Testimonials: Real Experiences
        </h1>
        <p>
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          has a long list of satisfied clients. Here's what a few had to say:
        </p>
        <p>
          <i>
            “Zzeeh Weddings made our traditional Hindu wedding flawless. From
            the Sangeet to the reception, everything was beyond perfect. They
            are undoubtedly one of the{" "}
            <span className="font-semibold">
              best wedding planners near me.
            </span>
            ”
          </i>{" "}
          – Rohan and Priya
        </p>
        <p>
          <i>
            “Their attention to detail and seamless execution made our wedding
            stress-free. Zzeeh is the top choice for anyone searching for{" "}
            <span className="font-semibold">
              affordable wedding planners near me.
            </span>
            ”
          </i>{" "}
          – Anjali and Karthik
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/intimate-indian-wedding.webp"
        alt="budget-decor-ideas"
      />

      <section id="budget-friendly-packages" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget-Friendly Packages
        </h3>
        <DynamicList newLine listType="list-[lower-alpha]" data={list4} />
      </section>

      <section id="cultural-elements" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          How Zzeeh Weddings Incorporates Cultural Elements
        </h3>
        <DynamicList listType="list-decimal" data={list5} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/intimate-wedding-outdoor.webp"
        alt="success-stories"
      />

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          has earned its reputation as one of the{" "}
          <span className="font-semibold">best wedding planners</span> in
          Bangalore by perfectly blending tradition and modernity. Whether
          you’re planning a palace wedding, an intimate temple ceremony, or a
          luxurious resort event, they are the{" "}
          <span className="font-semibold">wedding organizers</span> you can
          trust to create unforgettable memories.
        </p>
        <p>
          Ready to make your dream wedding a reality? Reach out to Zzeeh
          Weddings today—your trusted{" "}
          <span className="font-semibold">professional wedding planner</span> in
          Bangalore!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
