import React from "react";
import CTAButton from "../ui/CTAButton";
import dawn from "../../assets/images/ui/gold-dawn.webp";
import { Pendant } from "../../assets/icons/Icons";

const WhyChooseUs = ({
  setVisible,
}: {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <section
      id="why-choose-us"
      className="relative py-10 lg:p-10 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
        <img src={dawn} alt="dawn" className="hidden md:flex" />
      </div>
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 rotate-180">
        <img src={dawn} alt="dawn" className="hidden md:flex" />
      </div>

      <div className="flex flex-col items-center text-center">
        <div className="space-y-2 lg:space-y-4">
          <div className="flex flex-row items-center justify-center space-x-2 md:space-x-4 mb-6">
            <div className="rotate-180">
              <Pendant />
            </div>
            <p className="text-pureGold text-sm lg:text-base font-medium">
              WHY CHOOSE US?
            </p>
            <Pendant />
          </div>
          <h2 className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage lg:max-w-[820px]">
            The Best Wedding Planners & Decorators in Bangalore for your
            <i className="text-gold font-gt-super"> dream</i> celebration!
          </h2>
        </div>

        <div className="flex flex-col space-y-5 my-10 md:w-[500px] lg:w-[650px] xl:w-[800px]">
          <p className="text-purple text-sm lg:text-lg xl:text-xl">
            Every love story deserves a unique celebration! Our passion is
            crafting extraordinary weddings that reflect your personal style and
            exceed expectations. As expert wedding planners, we help you find
            the best wedding planners near you to ensure your special day is
            seamless and stress-free.
          </p>
          <p className="text-purple text-sm lg:text-lg xl:text-xl">
            With meticulous attention to detail and a deep understanding of your
            vision, we create unforgettable wedding experiences that you and
            your loved ones will cherish forever. Whether you're looking for the
            best wedding planners in Bangalore or the best wedding decorators in
            Bangalore, our dedicated team guarantees that every detail of your
            celebration is flawlessly executed.
          </p>
          <p className="text-purple text-sm lg:text-lg xl:text-xl">
            "From intimate ceremonies to grand receptions, our expert wedding
            planning services ensure your dream wedding becomes a reality with
            unparalleled expertise."
          </p>
        </div>

        <CTAButton
          name={`Book Your Dream Wedding`}
          handleClick={() => setVisible(true)}
        />
      </div>
    </section>
  );
};

export default WhyChooseUs;
