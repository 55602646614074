import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../components/ui/Navbar";
import Footer from "../components/ui/Footer";
import { useParams } from "react-router-dom";
import { blogs } from "../assets/data/blogs";
import FollowUs from "../components/Blogs/FollowUs";

const Blog = () => {
  const { blog_id } = useParams();
  const blog = blogs.find((b) => b.id === blog_id);
  const BlogContent = blog?.blog;

  return (
    <div className="flex flex-col items-center">
      <Helmet>
        <meta
          name="description"
          content={blog?.text || "Read our latest wedding blogs"}
        />
        <meta property="og:title" content={blog?.title} />
        <meta property="og:description" content={blog?.text} />
        <meta property="og:image" content={blog?.image} />
        <meta property="og:url" content={blog?.image} />
      </Helmet>

      <NavigationBar />
      <div className="container flex flex-col lg:flex-row min-h-screen px-3 xl:px-0 pb-10 mt-24 lg:mt-40">
        <div className="lg:w-2/3 xl:w-3/4 pb-10 lg:pe-10">
          {BlogContent ? <BlogContent /> : <h1>Our latest wedding blog</h1>}
        </div>
        <FollowUs />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
