import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "your-dream-wedding",
      label: "Your Dream Wedding Starts Here",
    },
    {
      id: "wedding-plans",
      label: "Wedding Plans Designed for You",
    },
    {
      id: "wide-range-services",
      label: "A Wide Range of Wedding Services",
    },
    {
      id: "stress-free-planning",
      label: "Stress-Free Wedding Planning",
    },
    {
      id: "zzeeh-difference",
      label: "The Zzeeh Difference",
    },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        From Vision to Reality: Zzeeh Weddings – Your Premier Wedding Event
        Planner in Bangalore
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/Qhu87NyFtgc?si=TR7bMTjW87JvTK3I"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Planning your wedding is an exciting yet challenging experience, as it
        requires not only creativity but also precise attention to detail. If
        you're searching for{" "}
        <span className="font-semibold">
          wedding planning experts in Garden City
        </span>
        , look no further than{" "}
        <a
          href="/zzeeh_weddings"
          className="text-blue-500 font-semibold hover:underline"
        >
          Zzeeh Weddings
        </a>
        , the top choice for{" "}
        <span className="font-semibold">
          luxury wedding planners in Bangalore
        </span>
        . With years of experience in{" "}
        <span className="font-semibold">
          wedding event management and marriage event management
        </span>
        , we specialize in turning your wedding vision into a beautiful reality.
        Whether you're seeking{" "}
        <span className="font-semibold">
          wedding planning near me or wedding agencies
        </span>{" "}
        that offer comprehensive services, Zzeeh Weddings has got you covered.
      </p>

      <TableofContent tocData={tocData} />

      <section id="your-dream-wedding" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Your Dream Wedding Starts Here
        </h1>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we understand that every couple has a unique vision for their
          special day. Our team of expert{" "}
          <span className="font-semibold">wedding organisers near me</span>{" "}
          ensures that no detail is overlooked. We provide personalized{" "}
          <span className="font-semibold">marriage event planning</span> and
          create an event that's tailored specifically to your tastes. From the
          first consultation to the final toast, we handle everything with
          meticulous care.
        </p>
        <p>
          <span className="font-semibold">Wedding planners in Garden City</span>{" "}
          are in abundance, but{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          stands out for our dedication to quality and our ability to make your
          wedding dreams come true. As your{" "}
          <span className="font-semibold">marriage event planner</span>, we
          provide end-to-end services, including{" "}
          <span className="font-semibold">stage wedding decoration</span>, to
          ensure your ceremony looks stunning and runs smoothly.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/bollywood-style-wedding-planning.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="wedding-plans" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Plans Designed for You
        </h1>
        <p>
          Every wedding needs a solid foundation, and that starts with a
          well-organized{" "}
          <span className="font-semibold">marriage plan list</span>. We
          collaborate with you to create a{" "}
          <span className="font-semibold">wedding plan</span> that reflects your
          desires, needs, and budget. From{" "}
          <span className="font-semibold">wedding event management</span> to the
          final execution, we ensure everything goes off without a hitch.
          Whether you're planning a grand celebration or an intimate gathering,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          can tailor our services to suit your needs.
        </p>
        <p>
          We offer complete{" "}
          <span className="font-semibold">wedding and event management</span>{" "}
          that encompasses all aspects of your special day. From the venue
          selection to catering, decorations, and entertainment, our team works
          seamlessly to provide a stress-free experience. As one of the{" "}
          <span className="font-semibold">
            best wedding organizers in Garden City
          </span>
          , we have built a reputation for excellence, ensuring every event we
          manage exceeds expectations.
        </p>
      </section>

      <section id="wide-range-services" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          A Wide Range of Wedding Services
        </h2>
        <p>
          We provide more than just planning. Our services extend to every
          corner of wedding preparation, offering bespoke solutions such as:
        </p>
        <ul className="list-disc px-5 ml-5">
          <li>
            Wedding planning experts in Garden City for strategic advice and
            insights.
          </li>
          <li>
            Wedding event management to oversee every detail, large and small.
          </li>
          <li>
            Stage wedding decoration that adds the perfect touch to your
            ceremony.
          </li>
          <li>Custom-designed wedding plans for all styles and budgets.</li>
          <li>
            Expertise in handling both grand weddings and intimate gatherings.
          </li>
        </ul>
        <p>
          No matter the scope of your event, we work tirelessly to bring your
          dreams to life. Our reputation as{" "}
          <span className="font-semibold">
            luxury wedding planners in Garden City
          </span>{" "}
          is backed by our commitment to delivering only the highest quality
          services to every couple.
        </p>
      </section>

      <section id="stress-free-planning" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Stress-Free Wedding Planning
        </h2>
        <p>
          If you're feeling overwhelmed by all the logistics, it's time to rely
          on our expertise.{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          offers a range of planning services for every part of your big day.
          Whether you're searching for a{" "}
          <span className="font-semibold">wedding planner near me</span> or you
          want to find the best{" "}
          <span className="font-semibold">marriage event planners</span>, our
          team is ready to make the entire process as stress-free as possible.
        </p>
        <p>
          We pride ourselves on offering personalized, hands-on assistance from
          the moment you book us. We’re not just your{" "}
          <span className="font-semibold">wedding planners</span>, but your
          partners in creating an unforgettable experience. From the{" "}
          <span className="font-semibold">wedding and event management</span> to{" "}
          <span className="font-semibold">stage wedding decoration</span>, we
          manage everything to perfection so you can focus on enjoying your
          special day.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/budget-event-planners-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section id="zzeeh-difference" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Zzeeh Difference
        </h2>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we combine passion, creativity, and experience to deliver
          exceptional wedding planning services. We are recognized as the{" "}
          <span className="font-semibold">wedding planning experts</span> in
          Garden City and have earned a reputation for being the{" "}
          <span className="font-semibold">
            best wedding organisers in Garden City
          </span>
          . When you choose us, you’re partnering with a team that truly cares
          about making your wedding day extraordinary. We work tirelessly to
          ensure that every detail is executed flawlessly, creating a seamless
          experience from start to finish.
        </p>
        <p>
          With{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , you can be confident that your wedding will be a magical,
          unforgettable experience. No matter what you envision for your big
          day, our team will bring it to life. Reach out to us today, and let's
          start planning the wedding of your dreams!
        </p>
      </section>

      {/* <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/pre-wedding-ceremony.webp"
        alt="success-stories"
      /> */}

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          Planning a{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            wedding
          </a>{" "}
          is a monumental task, but with the right wedding planner by your side,
          your dream wedding can become a reality. From finding the perfect
          venue to ensuring every detail aligns with your vision, a professional{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            wedding
          </a>{" "}
          planner can transform your big day into a truly magical event. If
          you're tying the knot in the Garden City, you're in the best hands
          with its top wedding planners.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
