import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "introduction", label: "Introduction" },
    {
      id: "budget-friendly-weddings",
      label: "Why Budget-Friendly Weddings Are the New Trend",
    },
    { id: "planning-basics", label: "Planning Basics: Setting the Foundation" },
    { id: "venue-selection", label: "Venue Selection: The Game-Changer" },
    { id: "wedding-themes", label: "Wedding Themes and DIY Decorations" },
    { id: "guest-list", label: "The Guest List: Quality Over Quantity" },
    { id: "catering", label: "Catering on a Budget" },
    {
      id: "photography-videography",
      label: "Photography and Videography: Memories That Don’t Break the Bank",
    },
    {
      id: "bridal-groom-attire",
      label: "Bridal Wear and Groom Attire: Fashion Within Limits",
    },
    { id: "invitations", label: "Invitations That Impress Without Stress" },
    { id: "entertainment-music", label: "Entertainment and Music on a Budget" },
    {
      id: "transport-accommodation",
      label: "Transport and Accommodation Hacks",
    },
    {
      id: "wedding-planners-vs-diy",
      label: "Wedding Planners vs. DIY Weddings",
    },
    {
      id: "smart-splurging",
      label: "Smart Splurging: When to Spend and When to Save",
    },
    { id: "conclusion", label: "Conclusion" },
    { id: "faqs", label: "FAQs" },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Budget-Friendly Wedding Planning in Bangalore: Tips to Save Big
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/7vLC08Ph9bY?si=WDS3XOfeHpV3jJR-"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Planning a wedding is one of the most exciting yet overwhelming tasks.
          If you're in Bangalore, you know how costs can escalate in no time!
          But here’s the good news: you don’t need a lavish budget to create the
          wedding of your dreams. With some clever planning and creative ideas,
          you can have a stunning wedding without breaking the bank. Let’s dive
          into how you can make it happen!
        </p>
      </section>

      <section id="budget-friendly-weddings" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Budget-Friendly Weddings Are the New Trend
        </h1>
        <p>
          Modern couples are redefining what it means to tie the knot. The
          emphasis is shifting from grandiosity to intimacy and experience. Why
          spend a fortune on one day when you can invest in your future
          together? A budget-friendly wedding allows you to celebrate your love
          story in a meaningful way while keeping financial stress at bay.
        </p>
      </section>

      <section id="planning-basics" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Planning Basics: Setting the Foundation
        </h1>
        <p>
          The first step to budget-friendly planning is knowing your limits.
          Start by setting a realistic budget and sticking to it. Make a list of
          priorities—what’s non-negotiable, and where can you cut back? For
          instance, if food and photography are must-haves, look for ways to
          save on decor and entertainment.
        </p>
      </section>

      <section id="venue-selection" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Venue Selection: The Game-Changer
        </h1>
        <p>
          Venues often take up a significant chunk of the budget. Opt for
          community halls, small resorts, or even outdoor locations like gardens
          and parks. Bangalore has plenty of affordable venue options. Always
          negotiate—it’s amazing how much you can save by just asking for a
          discount!
        </p>
      </section>

      <section id="wedding-themes" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Themes and DIY Decorations
        </h1>
        <p>
          A minimalistic theme can be both classy and cost-effective. Use fairy
          lights, fresh flowers, and recycled materials for decor. Want to add a
          personal touch? Get crafty with DIY centerpieces, backdrops, and table
          settings.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/low-budget-wedding-services-in-bangalore.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="guest-list" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Guest List: Quality Over Quantity
        </h1>
        <p>
          Be honest—do you need to invite that distant cousin you last saw 10
          years ago? A smaller guest list not only saves money but also ensures
          a more intimate celebration. Use online tools to manage RSVPs and keep
          track of who’s coming.
        </p>
      </section>

      <section id="catering" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Catering on a Budget
        </h1>
        <p>
          Food is one area where you can’t compromise on quality, but you can
          certainly trim the cost. Opt for a buffet-style setup with local
          delicacies. Bangalore’s caterers offer a variety of budget-friendly
          options that are sure to impress.
        </p>
      </section>

      <section id="photography-videography" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Photography and Videography: Memories That Don’t Break the Bank
        </h1>
        <p>
          Hiring a professional photographer doesn’t have to be expensive. Look
          for emerging talent or freelance photographers who can capture
          beautiful moments. Alternatively, set up a photo booth for guests to
          snap their own memories.
        </p>
      </section>

      <section id="bridal-groom-attire" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Bridal Wear and Groom Attire: Fashion Within Limits
        </h1>
        <p>
          Who says you need a brand-new designer outfit? Renting or buying
          pre-loved wedding attire is both sustainable and affordable. Bangalore
          has several boutiques specializing in budget-friendly bridal wear.
        </p>
      </section>

      <section id="invitations" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Invitations That Impress Without Stress
        </h1>
        <p>
          Switch to digital invites—they’re eco-friendly, cost-effective, and
          customizable. If you prefer traditional invites, consider making them
          yourself with some help from online design platforms.
        </p>
      </section>

      <section id="entertainment-music" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Entertainment and Music on a Budget
        </h1>
        <p>
          Instead of splurging on a DJ or live band, create a playlist of your
          favorite tracks. Need live music? Look for local artists or college
          bands who charge less but deliver excellent performances.
        </p>
      </section>

      <section id="transport-accommodation" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Transport and Accommodation Hacks
        </h1>
        <p>
          Coordinate shared transport for guests to reduce costs. For
          accommodation, partner with budget hotels or use homestays and
          vacation rentals to ensure comfort without overspending.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/hanging-wedding-decors.webp"
        alt="success-stories"
      />

      <section id="wedding-planners-vs-diy" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Planners vs. DIY Weddings
        </h1>
        <p>
          Hiring a planner doesn’t always mean shelling out big bucks. Some
          offer affordable packages for budget-conscious couples. Alternatively,
          take the DIY route—rope in family and friends to help execute your
          vision.
        </p>
      </section>

      <section id="smart-splurging" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Smart Splurging: When to Spend and When to Save
        </h1>
        <p>
          Spend on elements that matter most to you, like good food or an
          experienced photographer. Save on things like decor, stationery, or
          attire where you can get creative and resourceful.
        </p>
      </section>

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQ)
        </h1>
        <ul className={`list-disc ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              What is the average cost of a budget wedding in Bangalore?
            </p>
            <p>
              A budget wedding in Bangalore can range from ₹3 lakhs to ₹7 lakhs,
              depending on the guest count and preferences.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Can I have a dream wedding on a tight budget?
            </p>
            <p>
              Absolutely! By prioritizing and getting creative, you can craft a
              magical wedding without overspending.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              What are the best months to plan a budget wedding in Bangalore?
            </p>
            <p>
              Opt for off-season months like June or July to get better deals on
              venues and vendors.
            </p>
          </li>
          <li>
            <p className="font-semibold">How can I save on catering costs?</p>
            <p>
              Choose a buffet setup, limit the menu, and focus on local cuisines
              to reduce catering expenses.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Do budget-friendly weddings compromise quality?
            </p>
            <p>
              Not at all! With smart planning and the right choices, you can
              achieve quality without overspending.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Your dream wedding doesn’t have to come with a nightmare price tag.
          With a little creativity and strategic planning, you can host a
          memorable celebration that’s easy on the wallet. Remember, it’s not
          about how much you spend—it’s about the love and joy you share on your
          special day.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
