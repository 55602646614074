import React from "react";
import Footer from "../components/ui/Footer";
import NavigationBar from "../components/ui/Navbar";
import dawn from "../assets/images/ui/gold-dawn.webp";
import ClassFormOne from "../components/ClassFormOne";

const LearnOne = () => {
  return (
    <main className="flex flex-col items-center">
      <NavigationBar />

      <section
        id="learn"
        className="py-12 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
      >
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
          <img src={dawn} alt="dawn" className="hidden md:flex" />
        </div>
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 rotate-180">
          <img src={dawn} alt="dawn" className="hidden md:flex" />
        </div>

        <div className="flex flex-col items-center mt-20">
          <div className="flex flex-col text-center space-y-2 md:space-y-4 xl:w-[60vw]">
            <p className="text-sm font-semibold uppercase text-gold">
              Zzeeh Academy
            </p>
            <p className="text-dark text-3xl md:text-[40px] font-medium font-made-mirage text-center">
              Master the Art of Wedding Planning with Zzeeh
            </p>
            <p className="text-purple text-sm md:text-xl text-justify text-last-center">
              At Zzeeh Academy, we equip aspiring wedding planners with the
              skills, knowledge, and confidence to turn their passion into a
              successful profession. Whether you're starting fresh or refining
              your expertise, our expert-led courses will guide you every step
              of the way.
            </p>
          </div>

          {/* Video Section */}
          <div className="mt-8 w-full max-w-4xl">
            <iframe
              className="w-full rounded-lg shadow-lg aspect-video"
              src="https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1&loop=1&playlist=YOUR_VIDEO_ID"
              title="Zzeeh Wedding Planning"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <ClassFormOne />
      </section>

      <Footer />
    </main>
  );
};

export default LearnOne;
