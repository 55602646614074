import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "what-is-destination-wedding",
      label: "What is a Destination Wedding?",
    },
    { id: "what-is-local-wedding", label: "What is a Local Wedding?" },
    { id: "pros-destination", label: "Pros of a Destination Wedding" },
    { id: "cons-destination", label: "Cons of a Destination Wedding" },
    { id: "pros-local", label: "Pros of a Local Wedding" },
    { id: "cons-local", label: "Cons of a Local Wedding" },
    {
      id: "cost-comparison",
      label: "Cost Comparison: Destination Wedding vs. Local Wedding",
    },
    {
      id: "how-to-decide",
      label: "How to Decide Between a Destination and Local Wedding",
    },
    {
      id: "popular-destinations",
      label: "Popular Destination Wedding Spots for Bangalore Couples",
    },
    { id: "local-venues", label: "Local Wedding Venues in Bangalore" },
    {
      id: "tips-destination",
      label: "Tips for Planning a Destination Wedding",
    },
    { id: "tips-local", label: "Tips for Planning a Local Wedding" },
    { id: "faqs", label: "FAQs" },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list3 = [
    {
      header: "Unique Experience",
      body: "Destination weddings offer a one-of-a-kind experience for couples and guests. Imagine saying your vows against a sunset in Goa or amidst the grandeur of a Udaipur palace—pure magic!",
    },
    {
      header: "Memorable Location",
      body: "Exotic destinations create memories that last a lifetime. The photos and videos will look like something straight out of a movie, especially with the help of a destination wedding expert.",
    },
    {
      header: "Smaller Guest List",
      body: "Destination weddings naturally trim down the guest list, allowing you to celebrate with only your closest friends and family.",
    },
    {
      header: "A Built-In Honeymoon",
      body: "Your destination wedding venue can double as a honeymoon spot, saving time and additional travel expenses.",
    },
  ];

  const list4 = [
    {
      header: "Expensive for Guests",
      body: "Travel and accommodation costs can add up for your guests, making it difficult for everyone to attend.",
    },
    {
      header: "Limited Guest Attendance",
      body: "Not everyone can take time off for a destination wedding, meaning some important people might miss out.",
    },
    {
      header: "Complicated Logistics",
      body: "Coordinating travel, accommodations, and vendors in a new location can be stressful.",
    },
  ];

  const list5 = [
    {
      header: "Convenience for All",
      body: "Everyone, including older relatives and family friends, can easily attend without worrying about travel.",
    },
    {
      header: "Lower Cost for Guests",
      body: "Your guests won’t need to shell out money for flights or hotels, making it more affordable for them.",
    },
    {
      header: "Larger Guest List",
      body: "Want a big fat Indian wedding? A local wedding allows you to invite everyone, from neighbors to distant relatives.",
    },
    {
      header: "Familiarity with Vendors",
      body: "You can choose trusted local vendors and wedding event planners who understand your vision.",
    },
  ];

  const list6 = [
    {
      header: "Can Feel Less Unique",
      body: "Compared to a destination wedding, a local wedding might lack the 'wow factor.'",
    },
    {
      header: "Potentially Larger Costs for the Couple",
      body: "A big guest list means higher costs for food, venue, and décor.",
    },
    {
      header: "Stressful Planning with Extended Family Involvement",
      body: "Managing family opinions and traditions can sometimes feel overwhelming.",
    },
  ];

  const list7 = [
    {
      header: "Destination Wedding Costs",
      body: "For Bangalore couples, an intimate destination wedding can range from ₹10-₹25 lakhs, depending on the location.",
    },
    {
      header: "Local Wedding Costs",
      body: "Local weddings often involve larger guest lists, with expenses ranging from ₹15-₹35 lakhs for a grand affair.",
    },
  ];

  const list8 = [
    {
      header: "Budget",
      body: "Destination weddings can be cost-effective for smaller gatherings, while local weddings suit larger crowds.",
    },
    {
      header: "Guest Preferences",
      body: "Consider whether your loved ones can travel.",
    },
    {
      header: "Dream Wedding Vision",
      body: "Choose a wedding that matches your style and personality.",
    },
  ];

  const list9 = [
    {
      header: "Goa",
      body: "Beaches and resorts make for a relaxed celebration.",
    },
    {
      header: "Udaipur",
      body: "The 'City of Lakes' offers royal palaces and stunning backdrops.",
    },
    { header: "Kerala", body: "Houseboats, backwaters, and scenic beauty." },
    {
      header: "Maldives",
      body: "Perfect for intimate beach weddings and honeymoons.",
    },
  ];

  const list10 = [
    {
      header: "Traditional Venues",
      body: "Bangalore Palace, temples, and cultural halls.",
    },
    {
      header: "Modern Venues",
      body: "Luxury hotels, farmhouses, and resorts around the city.",
    },
  ];

  const list11 = [
    {
      header: "Book Early",
      body: "Book travel and accommodations early to get the best deals.",
    },
    {
      header: "Hire an Expert",
      body: "Hire a destination wedding expert familiar with the location.",
    },
    {
      header: "Visit the Venue",
      body: "Visit the venue at least once before the wedding.",
    },
  ];

  const list12 = [
    {
      header: "Keep a Strict Guest List",
      body: "Avoid overspending by keeping the guest list manageable.",
    },
    {
      header: "Hire Reliable Planners",
      body: "Engage professional planners for décor, catering, and entertainment.",
    },
    {
      header: "Plan Backup Options",
      body: "Prepare for weather and venue issues.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Destination Wedding vs. Local Wedding: Pros and Cons for Bangalore
        Couples
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/PzCZqn47ejs?si=NCFzRFYmmaIINDU8"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Weddings are a magical affair, but the big question remains: Should you
        have a grand local wedding or jet off to an exotic destination? For
        couples in Bangalore, the decision can be tricky. Let’s break down the
        pros and cons of both options so you can make the perfect choice for
        your big day!
      </p>

      <TableofContent tocData={tocData} />

      <section id="what-is-destination-wedding" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          What is a Destination Wedding?
        </h1>
        <p>
          A destination wedding is when couples choose to tie the knot in a
          picturesque location away from their hometown. Think sandy beaches,
          stunning palaces, or even lush hill stations. The entire celebration
          becomes a vacation for the couple and their guests. Professional
          destination wedding planners or a luxury destination wedding planner
          can make the experience stress-free and memorable.
        </p>
      </section>

      <section id="what-is-local-wedding" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          What is a Local Wedding?
        </h1>
        <p>
          A local wedding, as the name suggests, takes place close to home,
          often in a venue within the city or nearby areas. Wedding event
          planners often recommend local weddings for couples who prefer
          convenience, familiarity, and hosting a large guest list.
        </p>
      </section>

      <section id="pros-destination" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Pros of a Destination Wedding
        </h1>
        <DynamicList newLine listType="list-decimal" data={list3} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/destination-wedding-saudi.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="cons-destination" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Cons of a Destination Wedding
        </h1>
        <DynamicList newLine listType="list-decimal" data={list4} />
      </section>

      <section id="pros-local" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Pros of a Local Wedding
        </h1>
        <DynamicList newLine listType="list-decimal" data={list5} />
      </section>

      <section id="cons-local" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Cons of a Local Wedding
        </h1>
        <DynamicList newLine listType="list-decimal" data={list6} />
      </section>

      <section id="cost-comparison" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Cost Comparison: Destination Wedding vs. Local Wedding
        </h1>
        <DynamicList newLine listType="" data={list7} />
      </section>

      <section id="how-to-decide" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          How to Decide Between a Destination and Local Wedding
        </h1>
        <DynamicList listType="list-decimal" data={list8} />
      </section>

      <section id="popular-destinations" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Popular Destination Wedding Spots for Bangalore Couples
        </h1>
        <DynamicList listType="list-disc" data={list9} />
      </section>

      <section id="local-venues" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Local Wedding Venues in Bangalore
        </h1>
        <DynamicList listType="list-disc" data={list10} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planning-bangalore.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="tips-destination" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Tips for Planning a Destination Wedding
        </h1>
        <DynamicList listType="list-decimal" data={list11} />
      </section>

      <section id="tips-local" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Tips for Planning a Local Wedding
        </h1>
        <DynamicList listType="list-decimal" data={list12} />
      </section>

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQs)
        </h1>
        <ul className={`list-decimal ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              What is the average cost of a destination wedding for Bangalore
              couples?
            </p>
            <p>
              The average cost ranges from ₹10-₹25 lakhs, depending on the
              location and guest count.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Is a destination wedding worth it for a small budget?
            </p>
            <p>
              Yes, with the help of destination Indian wedding planners, you can
              save money by keeping the guest list small.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              How early should you start planning a destination wedding?
            </p>
            <p>Start at least 9-12 months in advance for smooth planning.</p>
          </li>
          <li>
            <p className="font-semibold">
              Are destination weddings more stressful than local weddings?
            </p>
            <p>
              They can be, but hiring a destination wedding expert or a trusted
              planner makes the process much easier.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Can local weddings also be as unique as destination weddings?
            </p>
            <p>
              Absolutely! With creative themes and the help of wedding event
              planners, a local wedding can feel just as special.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Whether you dream of an intimate beach wedding in Goa or a grand
          celebration in your hometown, both options have their charm. By hiring
          the best destination wedding planners or professional wedding event
          planners, you can ensure a stress-free, magical experience that
          reflects your love story perfectly.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
