import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "guide",
      label:
        "Zzeeh Weddings: Your Ultimate Guide to Budget-Friendly Wedding Planners in Bangalore",
    },
    {
      id: "budget-planners",
      label: "Affordable Wedding Planners: Quality Services at a Budget Price",
    },
    {
      id: "photography",
      label: "Affordable Wedding Photography and Videography",
    },
    {
      id: "coordination",
      label: "Wedding Planning and Coordination Services",
    },
    {
      id: "resources",
      label: "Wedding Planning Websites and Resources",
    },
    {
      id: "destination-planner",
      label: "Destination Wedding Planner Services",
    },
    {
      id: "costs",
      label: "Transparent Wedding Planner Costs",
    },
    {
      id: "why-zzeeh",
      label: "Why Choose Zzeeh Weddings for Your Wedding Planning?",
    },
    {
      id: "contact",
      label: "Contact Zzeeh Weddings Today",
    },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Expert Wedding Coordinators",
      body: "Whether you need full planning or a day-of coordinator, our wedding professionals ensure that your day runs smoothly.",
    },
    {
      header: "Affordable Photography & Videography",
      body: "We offer connections to the best photographers and videographers near me at affordable prices.",
    },
    {
      header: "Custom Packages",
      body: "We provide budget-friendly wedding planners with tailored services that suit your style and budget.",
    },
    {
      header: "Complete Wedding Planning Services",
      body: "From finding the perfect venue to coordinating every detail, our wedding event planner services have you covered.",
    },
    {
      header: "Transparent Pricing",
      body: "We offer clear, upfront information about wedding planner cost, ensuring there are no hidden fees.",
    },
  ];
  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Top-Notch Wedding Planners in Bangalore at Budget Price
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/cbpIH_CkykM?si=7iSp2jav42Nou8zj"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="guide" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: Your Ultimate Guide to Budget-Friendly Wedding
          Planners in Bangalore
        </h1>
        <p>
          Planning a wedding is an exciting yet challenging process, especially
          when trying to balance your dream celebration with your budget. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we understand that every couple deserves a beautiful, memorable
          wedding without breaking the bank. As{" "}
          <span className="font-semibold">
            budget-friendly wedding planners
          </span>
          , we offer{" "}
          <span className="font-semibold">
            high-quality wedding planning services
          </span>{" "}
          designed to make your big day flawless—without the hefty price tag.
        </p>
        <p>
          If you’re searching for the{" "}
          <span className="font-semibold">
            best wedding planners near me, wedding coordinator services
          </span>
          , or even a{" "}
          <span className="font-semibold">destination wedding planner</span> in
          Bangalore,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is here to provide the expertise and attention to detail that ensures
          everything goes smoothly.
        </p>
      </section>

      <section id="budget-planners" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Affordable Wedding Planners: Quality Services at a Budget Price
        </h1>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we offer comprehensive wedding planning solutions at{" "}
          <span className="font-semibold">budget-friendly prices</span>. Our{" "}
          <span className="font-semibold">wedding planning company</span> is
          known for its attention to detail, and our{" "}
          <span className="font-semibold">wedding professionals</span> work with
          you to design a celebration that fits your budget, without sacrificing
          style or elegance. Whether you're looking for full-service planning or
          a <span className="font-semibold">day of wedding coordinator</span>,
          we have the experience to ensure your day unfolds perfectly.
        </p>
        <p>
          Our <span className="font-semibold">wedding organizers</span>{" "}
          specialize in handling all aspects of your wedding, from selecting the
          venue to coordinating the timeline. We offer a variety of packages and
          flexible services to cater to couples who need a little help or
          full-service management.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/top-banquet-halls-bangalore.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="photography" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Affordable Wedding Photography and Videography
        </h1>
        <p>
          Capturing the memories of your special day is essential, but wedding
          photography and videography can sometimes be one of the most expensive
          parts of wedding planning. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we believe in providing couples with access to high-quality{" "}
          <span className="font-semibold">
            photographer and videographer services
          </span>{" "}
          at affordable rates. If you're searching for{" "}
          <span className="font-semibold">cheap photographers near me</span> or{" "}
          <span className="font-semibold">
            cheap wedding photographers near me
          </span>
          , we can connect you with experienced professionals who offer stunning
          photos and videos without the high cost.
        </p>
        <p>
          Our{" "}
          <span className="font-semibold">
            wedding photographers and videographers near me
          </span>{" "}
          have extensive experience capturing every beautiful moment of your
          wedding day. Whether you need a full photography and videography
          package or just a{" "}
          <span className="font-semibold">day of coordinator</span> to manage
          the logistics on your wedding day, we ensure that every important
          moment is expertly documented.
        </p>
      </section>

      <section id="coordination" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Planning and Coordination Services
        </h1>
        <p>
          When you hire a{" "}
          <span className="font-semibold">wedding coordinator</span>, you’re
          enlisting someone to handle all the details so you can enjoy your day.
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we offer expert{" "}
          <span className="font-semibold">day of wedding coordinator</span>{" "}
          services to ensure everything runs smoothly. Our team takes care of
          all the last-minute details, ensuring that your ceremony and reception
          go off without a hitch.
        </p>
        <p>
          If you’re looking for a{" "}
          <span className="font-semibold">wedding coordinator near me</span> or{" "}
          <span className="font-semibold">wedding event planner</span> in
          Bangalore, we’re here to help. From coordinating your vendors to
          managing the timeline, we make sure everything stays on track,
          allowing you to relax and enjoy your big day.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/small-wedding-reception.webp"
        alt="budget-decor-ideas"
      />

      <section id="resources" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Planning Websites and Resources
        </h1>
        <p>
          In the digital age,{" "}
          <span className="font-semibold">wedding planning websites</span> and{" "}
          <span className="font-semibold">wedding planning sites</span> are
          great resources for brides and grooms looking for inspiration. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we provide you with a comprehensive list of trusted online resources
          that can help you throughout your planning process. Our{" "}
          <span className="font-semibold">best wedding planning website</span>{" "}
          offer a variety of tools, from budget calculators to checklists, all
          designed to make your planning journey stress-free.
        </p>
        <p>
          As a <span className="font-semibold">wedding planning company</span>,
          we stay updated on the latest trends and techniques in the industry,
          and we bring this knowledge to your wedding. Whether you're using
          online resources to plan or letting us handle all the details, we are
          here to guide you at every step.
        </p>
      </section>

      <section id="destination-planner" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Destination Wedding Planner Services
        </h3>
        <p>
          If you’re considering a{" "}
          <span className="font-semibold">destination wedding</span>,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is your go-to{" "}
          <span className="font-semibold">destination wedding planner</span>.
          Whether you're dreaming of a beautiful beach ceremony or a serene
          mountainside celebration, we have experience organizing weddings at
          various destinations in and around Bangalore. We provide affordable
          packages for couples who want a{" "}
          <span className="font-semibold">destination wedding</span> but are
          mindful of their budget.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-decorations-indian.webp"
        alt="success-stories"
      />

      <section id="costs" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Transparent Wedding Planner Costs
        </h3>
        <p>
          One of the most common questions couples have is about the{" "}
          <span className="font-semibold">wedding planner cost</span>. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we believe in transparency. We offer a clear breakdown of our
          services and fees, so you always know what to expect. Our goal is to
          provide high-quality wedding planning at a{" "}
          <span className="font-semibold">budget-friendly price</span>. From the
          initial consultation to the big day, our team works with you to design
          a wedding that fits your budget while exceeding your expectations.
        </p>
      </section>

      <section id="why-zzeeh" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings for Your Wedding Planning?
        </h3>
        <DynamicList listType="list-decimal" data={list1} />
      </section>

      <section id="contact" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Contact Zzeeh Weddings Today
        </h1>
        <p>
          If you’re looking for{" "}
          <span className="font-semibold">wedding planners near me</span> or a{" "}
          <span className="font-semibold">
            budget-friendly wedding organizer
          </span>{" "}
          in Bangalore,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is here to make your dreams a reality. We offer a variety of services
          to ensure your wedding day is as beautiful and stress-free as
          possible. Whether you're planning a grand celebration or an intimate
          gathering, we are dedicated to making your wedding day
          unforgettable—at a price you can afford.
        </p>
        <p>
          Let{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          handle the details, so you can focus on what matters most: celebrating
          your love. Contact us today to begin planning your dream wedding!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
