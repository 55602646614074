import React, { useEffect, useState } from "react";
import BlogSubHeader from "../../ui/BlogSubHeader";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction",
    },
    {
      id: "magic-of-wedding-planners",
      label: "The Magic of Wedding Planners: Why You Need One",
    },
    {
      id: "bangalore-wedding-destination",
      label: "Why Bangalore is the Ultimate Wedding Destination",
    },
    {
      id: "best-wedding-planners",
      label: "Best Wedding Planners in Bangalore",
    },
    {
      id: "zzeeh-weddings",
      label: "Zzeeh Weddings: A Symphony of Dreams",
    },
    {
      id: "experience-zzeeh",
      label: "Experience the Charm of Zzeeh",
    },
    {
      id: "vision-to-life",
      label: "How Zzeeh Weddings Brings Your Vision to Life",
    },
    {
      id: "creative-excellence-ideas",
      label: "Creative Excellence and Unique Ideas",
    },
    {
      id: "zzeeh-services-comprehensive",
      label: "Zzeeh’s Services: A Comprehensive Approach",
    },
    {
      id: "client-testimonials",
      label: "Client Testimonials: A Love Story Written by Zzeeh",
    },
    {
      id: "conclusion",
      label: "Conclusion: A Dream Wedding with Zzeeh is a Wish Come True",
    },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Full-Service Wedding Planning",
      body: "From budgeting to day-of coordination, Zzeeh handles it all.",
    },
    {
      header: "Exclusive Venue Selection",
      body: "Their connections with wedding companies in Bangalore ensure access to the best venues.",
    },
    {
      header: "Personalized Décor and Design",
      body: "Every element, from floral arrangements to lighting, reflects your unique style.",
    },
    {
      header: "Guest Management",
      body: "Zzeeh ensures a seamless experience for your guests, from accommodations to transportation.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Zzeeh Weddings: Best Wedding Planners in Bangalore 2024
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/_zWxJi6Ie5Y?si=fDSqS74uYdqGBZWQ"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <BlogSubHeader text="Introduction" />
        <p>
          There’s something magical about weddings—two souls uniting, surrounded
          by love, laughter, and cherished memories. But behind every dream
          wedding is a team that orchestrates every moment to perfection. When
          it comes to{" "}
          <span className="font-semibold">
            wedding planners in Bangalore, India
          </span>
          , no one does it better than{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          .
        </p>
      </section>

      <section id="magic-of-wedding-planners" className="space-y-3">
        <BlogSubHeader text="The Magic of Wedding Planners: Why You Need One" />
        <p>
          A wedding is one of the most important days of your life, but planning
          it can be overwhelming. From choosing the perfect venue to finalizing
          the guest list, every detail matters. That’s where{" "}
          <span className="font-semibold">
            wedding planning companies in Bangalore
          </span>{" "}
          like Zzeeh come in—a guiding hand through the chaos, a creative
          partner that turns your dreams into reality. They don’t just plan
          weddings; they craft experiences that linger in hearts forever.
        </p>
      </section>

      <section id="bangalore-wedding-destination" className="space-y-3">
        <BlogSubHeader text="Why Bangalore is the Ultimate Wedding Destination" />
        <p>
          Bangalore, with its sprawling gardens, enchanting weather, and a blend
          of modernity and tradition, has emerged as a hotspot for weddings.
          From luxurious hotels to serene open-air venues, the city offers an
          array of options for couples. With{" "}
          <span className="font-semibold">
            top wedding planners in Bangalore
          </span>
          , like Zzeeh, your special day transforms into a memorable
          celebration.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/marriage-flower-decoration.webp"
        alt="magic-of-wedding-planners"
      />

      <section id="best-wedding-planners" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Best Wedding Planners in Bangalore
        </h1>
        <p>
          Enter{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , a name synonymous with creativity, precision, and excellence.
          Recognized as one of the{" "}
          <span className="font-semibold">
            best wedding planners in Bangalore
          </span>
          , Zzeeh combines art, passion, and experience to craft weddings as
          unique as the love stories they celebrate. Whether you need a{" "}
          <span className="font-semibold">
            luxury wedding planner in Bangalore
          </span>{" "}
          or someone who can execute a simple, elegant celebration, Zzeeh
          delivers it all.
        </p>
      </section>

      <section id="zzeeh-weddings" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: A Symphony of Dreams
        </h2>
        <p>
          Imagine a wedding where every flower in the bouquet tells a story,
          every flicker of candlelight adds warmth, and every guest feels
          cherished. That’s the charm Zzeeh brings to life. As one of the{" "}
          <span className="font-semibold">
            top wedding planners in Bangalore
          </span>
          , Zzeeh doesn’t just organize weddings—they compose symphonies of
          dreams, creating memories that last a lifetime.
        </p>
      </section>

      <section id="experience-zzeeh" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Experience the Charm of Zzeeh
        </h3>
        <p>
          From the moment you meet the Zzeeh team, their professionalism and
          passion stand out. Their approach blends creative innovation with
          meticulous attention to detail. With Zzeeh by your side, you don’t
          just get a{" "}
          <span className="font-semibold">
            wedding management company in Bangalore
          </span>
          —you get a confidant who ensures your day is extraordinary.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/marriage-flower-decoration-price.webp"
        alt="personalized-decor"
      />

      <section id="vision-to-life" className="space-y-3">
        <BlogSubHeader text="How Zzeeh Weddings Brings Your Vision to Life" />
        <p>
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh's
          </a>{" "}
          philosophy is simple: no two weddings should be alike. Whether it’s a
          grand ballroom celebration or an intimate garden ceremony, they are
          masters of customization. Their ability to personalize every detail
          makes them stand out in the{" "}
          <span className="font-semibold">
            list of wedding planners in Bangalore
          </span>
          .
        </p>
      </section>

      <section id="creative-excellence-ideas" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Creative Excellence and Unique Ideas
        </h2>
        <p>
          Zzeeh thrives on innovation, bringing even the most unconventional
          ideas to life. From themed décor to unique surprises, they transform
          your imagination into reality. If you’re searching for{" "}
          <span className="font-semibold">wedding organizers in Bangalore</span>{" "}
          who can turn your wedding into a one-of-a-kind experience, Zzeeh is
          the answer.
        </p>
      </section>

      <section id="zzeeh-services-comprehensive" className="space-y-3">
        <BlogSubHeader text="Zzeeh’s Services: A Comprehensive Approach" />
        <p>
          Zzeeh offers a range of services designed to ensure your wedding is
          stress-free and flawless:
        </p>
        <DynamicList listType="list-disc" data={list1} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/flower-stage-decoration.webp"
        alt="vision-to-life"
      />

      <section id="client-testimonials" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Client Testimonials
        </h2>
        <p>Clients consistently praise Zzeeh’s dedication and creativity:</p>
        <ul className="list-disc">
          <li>
            <i>
              “Zzeeh took our dreams and turned them into a breathtaking
              reality. They truly are the{" "}
              <span className="font-semibold">
                best wedding planners in Bangalore
              </span>
              .”
            </i>
          </li>
          <li>
            <i>
              “From start to finish, Zzeeh made our wedding stress-free and
              magical. They’re unparalleled among{" "}
              <span className="font-semibold">
                wedding planning companies in Bangalore
              </span>
              .”
            </i>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <BlogSubHeader text="Conclusion: Trust Zzeeh for Your Dream Wedding" />
        <p>
          A wedding should reflect your love story, and with Zzeeh, that’s
          exactly what you’ll get. As a trusted name among{" "}
          <span className="font-semibold">wedding organizers in Bangalore</span>
          , Zzeeh doesn’t just create events—they craft memories etched in time.
        </p>
        <p>
          For anyone searching for{" "}
          <span className="font-semibold">
            luxury wedding planners in Bangalore
          </span>{" "}
          or a trusted{" "}
          <span className="font-semibold">
            wedding management company in Bangalore
          </span>
          , Zzeeh is your go-to. With them, your wedding isn’t just an event;
          it’s a masterpiece of love, crafted to perfection.
        </p>
      </section>
      <GetInTouch />
    </div>
  );
};

export default Blog;
