import React from "react";
import dawn from "../../assets/images/ui/gold-dawn.webp";
import { Pendant } from "../../assets/icons/Icons";

const TimelessWeddingMagic = () => {
  return (
    <section
      id="why-choose-us"
      className="relative py-10 lg:p-10 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
        <img src={dawn} alt="dawn" className="hidden md:flex" />
      </div>
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 rotate-180">
        <img src={dawn} alt="dawn" className="hidden md:flex" />
      </div>

      <div className="flex flex-col items-center text-center">
        <div className="space-y-2 lg:space-y-4">
          <div className="flex flex-row items-center justify-center space-x-2 md:space-x-4 mb-6">
            <div className="rotate-180">
              <Pendant />
            </div>
            <p className="text-pureGold text-sm lg:text-base font-medium uppercase">
              Timeless Wedding Magic
            </p>
            <Pendant />
          </div>
          <h1 className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage lg:max-w-[820px]">
            <i className="text-gold font-gt-super">Zzeeh ~ </i>Best Wedding
            Planners in Bangalore
          </h1>
        </div>

        <div className="flex flex-col my-10 md:w-[500px] lg:w-[650px] xl:w-[750px]">
          <h3 className="text-purple font-medium text-sm lg:text-lg xl:text-xl">
            We do not Create Weddings, We Create Unforgettable Experiences.
          </h3>
          <p className="text-purple text-sm lg:text-lg mt-3">
            Zzeeh Weddings stands out as one of the leading and best wedding
            planners in Bangalore, proudly recognized as the Best Wedding
            Planners in South India. As a one-stop solution for all your wedding
            needs, we understand the concerns that come with wedding planning.
            Our professional wedding planners ensure a seamless experience,
            making your special day truly memorable.
          </p>
          <h3 className="text-purple font-medium text-sm lg:text-lg xl:text-xl mt-5">
            Expert Wedding Planning for a Joyous & Memorable Celebration
          </h3>
          <p className="text-purple text-sm lg:text-lg xl mt-3">
            Our exceptional wedding planning team ensures your wedding day is
            the most fun-filled and unforgettable event imaginable. We
            prioritize your happiness, going above and beyond to create a joyous
            and auspicious celebration.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TimelessWeddingMagic;
