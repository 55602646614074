import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "intro",
      label:
        "Transform Your Dream Wedding with Zzeeh Weddings: Expert Wedding Event Management in Bangalore",
    },
    { id: "tailored-plans", label: "Tailored Wedding Plans for Every Couple" },
    {
      id: "planning-services",
      label: "Professional Wedding Planning Services Near You",
    },
    {
      id: "event-management",
      label: "Comprehensive Marriage Event Management",
    },
    { id: "why-choose", label: "Why Choose Zzeeh Weddings?" },
    { id: "stage-decoration", label: "Stage Wedding Decoration and More" },
    {
      id: "stress-free",
      label: "Stress-Free Wedding Planning with Zzeeh Weddings",
    },
    { id: "experience", label: "The Zzeeh Weddings Experience" },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Experience",
      body: "With years of expertise in wedding planning, we have perfected the art of wedding and event management.",
    },
    {
      header: "Personalized Service",
      body: "We understand that no two weddings are the same. Our team creates a unique marriage event plan just for you.",
    },
    {
      header: "Attention to Detail",
      body: "From the smallest detail to the grandest gesture, we ensure your wedding day is flawless.",
    },
    {
      header: "Full-Service Event Management",
      body: "We offer complete wedding event management services, from conceptualization to execution.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Budget Hindu Wedding Decor by Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/igtyEtRm9l8?si=NoYyM1r7n40wz7tP"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Planning a beautiful Hindu wedding doesn’t have to mean breaking the
        bank. With{" "}
        <a
          href="/zzeeh_weddings"
          className="text-blue-500 font-semibold hover:underline"
        >
          Zzeeh Weddings’
        </a>{" "}
        expertise in elegant and budget-friendly decor, you can bring the
        grandeur of traditional Hindu aesthetics to life. Let’s explore how
        Zzeeh Weddings creates a memorable ambiance while keeping it
        wallet-friendly.
      </p>

      <TableofContent tocData={tocData} />

      <section id="intro" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Transform Your Dream Wedding with Zzeeh Weddings: Expert Wedding Event
          Management in Bangalore
        </h1>
        <p>
          Planning a wedding is an exciting journey, but it can also be
          overwhelming. Every couple dreams of a day that’s seamless, beautiful,
          and memorable. With{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , the{" "}
          <span className="font-semibold">
            top wedding event planner in Bangalore
          </span>
          , you can turn your dream wedding into a reality. Specializing in{" "}
          <span className="font-semibold">wedding event management</span> and{" "}
          <span className="font-semibold">marriage event management</span>, our
          team ensures that every detail is meticulously planned and executed.
        </p>
      </section>

      <section id="tailored-plans" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Tailored Wedding Plans for Every Couple
        </h1>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we believe that every wedding is unique. Whether you’re looking for
          a simple celebration or an extravagant event, we work closely with you
          to create <span className="font-semibold">wedding plans</span> that
          reflect your personality and style. From the initial concept to the
          final celebration, our experienced team guides you through every step
          of the process. As one of the best{" "}
          <span className="font-semibold">wedding agencies</span> in Bangalore,
          we offer bespoke services tailored to your needs.
        </p>
        <p>
          Our expert <span className="font-semibold">wedding planners</span> are
          here to support you at every stage, ensuring that your special day
          runs smoothly. We know that planning a wedding can be stressful, which
          is why we offer comprehensive{" "}
          <span className="font-semibold">wedding and event management</span>{" "}
          services that cover every detail.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/bollywood-style-wedding-planning.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="planning-services" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Professional Wedding Planning Services Near You
        </h2>
        <p>
          If you’re searching for{" "}
          <span className="font-semibold">
            wedding planning near me or wedding organisers near me
          </span>
          ,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is the answer. We’re conveniently located in Bangalore, and our team
          is dedicated to providing the highest level of service. Whether you're
          planning a traditional celebration or a modern affair, our{" "}
          <span className="font-semibold">marriage event planners</span> bring
          expertise and creativity to the table.
        </p>
        <p>
          We work with you to develop a{" "}
          <span className="font-semibold">marriage plan list</span>, ensuring
          that no detail is overlooked. From choosing the right venue to
          selecting décor, catering, and entertainment, our team handles all
          aspects of{" "}
          <span className="font-semibold">wedding event management</span> so you
          can focus on enjoying the process.
        </p>
      </section>

      <section id="event-management" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Comprehensive Marriage Event Management
        </h2>
        <p>
          As seasoned experts in{" "}
          <span className="font-semibold">marriage event management</span>,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          knows how to bring your vision to life. We provide all-inclusive
          services that include everything from venue selection and budgeting to
          the final touches on your big day. Whether it’s a grand celebration or
          a more intimate gathering, our{" "}
          <span className="font-semibold">wedding planners</span> make sure
          every aspect is taken care of.
        </p>
        <p>
          We understand that every couple has different preferences and needs,
          which is why we customize each{" "}
          <span className="font-semibold">wedding plan</span> to suit your
          budget and style. From{" "}
          <span className="font-semibold">stage wedding decoration</span> to
          guest coordination, our team works diligently to ensure your wedding
          is perfect in every way.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/budget-event-planners-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section id="why-choose" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings?
        </h2>
        <DynamicList listType="list-disc" data={list1} />
      </section>

      <section id="stage-decoration" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Stage Wedding Decoration and More
        </h2>
        <p>
          One of the highlights of every wedding is the décor. Our{" "}
          <span className="font-semibold">stage wedding decoration</span>{" "}
          services are designed to create an unforgettable setting for your
          ceremony and reception. Whether you envision a traditional or
          contemporary theme, our team brings your ideas to life with elegance
          and flair.
        </p>
      </section>

      <section id="stress-free" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Stress-Free Wedding Planning with Zzeeh Weddings
        </h2>
        <p>
          When it comes to{" "}
          <span className="font-semibold">planning wedding</span> details,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is your trusted partner. Our expert{" "}
          <span className="font-semibold">wedding planners</span> handle
          everything, from the initial consultation to the final send-off,
          ensuring that your big day is nothing short of perfect. Whether you’re
          looking for guidance on a{" "}
          <span className="font-semibold">wedding plan list</span> or help with
          choosing the right{" "}
          <span className="font-semibold">marriage event planner</span>, we’re
          here to assist.
        </p>
        <p>
          With{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , you don’t have to worry about a thing. Our comprehensive{" "}
          <span className="font-semibold">wedding event management</span>{" "}
          services ensure that your wedding day is everything you’ve dreamed of
          and more. We take care of every detail, from{" "}
          <span className="font-semibold">wedding planning near me</span> to{" "}
          <span className="font-semibold">stage wedding decoration</span>, so
          you can enjoy a stress-free celebration.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/pre-wedding-ceremony.webp"
        alt="success-stories"
      />

      <section id="experience" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Zzeeh Weddings Experience
        </h2>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we are passionate about creating beautiful, personalized weddings
          that reflect the love and joy of each couple. As one of the leading{" "}
          <span className="font-semibold">wedding event planners</span> in
          Bangalore, we pride ourselves on our ability to deliver excellence.
          Whether you need assistance with{" "}
          <span className="font-semibold">marriage event management</span> or
          help in creating the perfect{" "}
          <span className="font-semibold">wedding plan</span>, our team is here
          to guide you every step of the way.
        </p>
        <p>
          Let us handle the complexities of your wedding so that you can focus
          on enjoying the celebration with your loved ones. Reach out to{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          today, and let’s begin planning the wedding of your dreams!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
