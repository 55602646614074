import React from "react";
import NavigationBar from "../../components/ui/Navbar";
import Footer from "../../components/ui/Footer";

const Privacy = () => {
  return (
    <div className="flex flex-col items-center bg-[#f6f6f6]">
      <NavigationBar />

      <div className="px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full py-20 md:py-32 text-justify">
        <p className="self-center whitespace-nowrap text-3xl font-semibold">
          Privacy Policy
        </p>
        <p className="mt-1">Last Updated on August 28th, 2024</p>
        <p className="mt-3">
          This Privacy Policy ("<text className="font-bold">Policy</text>")
          explains how Zzeeh Productions Pvt Ltd ("Zzeeh," "we," "us," or "our")
          collects, uses, shares, and protects personal information about you.
          This Policy applies to personal information we collect through our
          website, mobile applications, and other online services (collectively,
          the "Services").
        </p>
        <p className="mt-7 font-semibold text-xl">
          Collection of Personal Information
        </p>
        <p>
          We collect personal information from you when you engage our event
          planning services, request a consultation, or interact with our
          website and services. The types of personal information we collect may
          include:
        </p>
        <div className="lg:ml-14 ml-8 mt-2">
          <ul className="list-decimal">
            <li>
              <text className="font-bold">Contact Information: </text>
              <text>
                We collect your name, email address, phone number, and other
                contact details to communicate with you about your event,
                bookings, and updates.
              </text>
            </li>
            <li>
              <text className="font-bold">Event Details: </text>
              <text>
                We collect information about your event, including the date,
                venue, guest lists, and any specific requirements to provide
                customized services.
              </text>
            </li>
            <li>
              <text className="font-bold">Payment Information: </text>
              <text>
                We collect payment information for processing transactions
                related to event bookings, services, and other charges.
              </text>
            </li>
            <li>
              <text className="font-bold">Demographic Information: </text>
              <text>
                We may collect demographic data such as age, preferences, and
                interests to tailor our event services to your needs.
              </text>
            </li>
          </ul>
        </div>
        <p className="mt-7 font-semibold text-xl">
          Use of Personal Information
        </p>
        <p>We use your personal information to:</p>
        <div className="lg:ml-14 ml-8 mt-2">
          <ul className="list-decimal">
            <li>
              <text className="font-bold">
                Provide Event Planning Services:{" "}
              </text>
              <text>
                Use your event details and preferences to create and execute
                your wedding or event as per your requirements.
              </text>
            </li>
            <li>
              <text className="font-bold">Communicate with You: </text>
              <text>
                Keep you updated on your event, send you reminders, and provide
                consultation based on your preferences.
              </text>
            </li>
            <li>
              <text className="font-bold">Personalize Your Experience: </text>
              <text>
                Tailor our recommendations and services to suit your personal
                preferences and needs.
              </text>
            </li>
            <li>
              <text className="font-bold">Process Payments: </text>
              <text>
                Use your payment information to process transactions for event
                bookings and related services.
              </text>
            </li>
            <li>
              <text className="font-bold">
                Comply with Legal Requirements:{" "}
              </text>
              <text>
                Fulfill legal obligations related to financial reporting, fraud
                prevention, and regulatory compliance.
              </text>
            </li>
          </ul>
          <p className="mt-3">
            We do not sell or share your personal information with third parties
            except as necessary to provide our services. We may share your
            information with trusted partners, such as venues, decorators,
            caterers, and other service providers essential to your event. These
            third parties are only permitted to use your information for the
            purpose of supporting your event and are required to protect your
            data.
          </p>
        </div>
        <p className="mt-7 font-semibold text-xl">
          Protection of Personal Information
        </p>
        <p>
          We prioritize the security of your personal information and have
          implemented appropriate technical and organizational measures to
          protect it from unauthorized access, use, or disclosure.
        </p>
        <p className="mt-7 font-semibold text-xl">
          Retention of Personal Information
        </p>
        <p>
          We retain your personal information only as long as necessary to
          provide the services you requested or as required by law.
        </p>
        <p className="mt-7 font-semibold text-xl">Your Rights</p>
        <p>
          You have the right to access, modify, or delete your personal
          information at any time. To exercise these rights, please contact us
          at{" "}
          <a href="mailto:info@zzeeh.com" className="underline text-blue-600">
            info@zzeeh.com
          </a>
          .
        </p>
        <p>
          If you believe your privacy rights have been violated, you may file a
          complaint with the relevant regulatory authority.
        </p>
        <p className="mt-7 font-semibold text-xl">
          Updates to this Privacy Policy
        </p>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. Please review this Policy regularly to stay informed about how
          we protect your personal information.
        </p>
        <p className="mt-7 font-semibold text-xl">Contact Us</p>
        <p>
          For any questions or concerns regarding our privacy practices, please
          reach out to us at{" "}
          <a href="mailto:info@zzeeh.com" className="underline text-blue-600">
            info@zzeeh.com
          </a>
          .
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
