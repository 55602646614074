import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction-destination-weddings",
      label: "Introduction to Destination Weddings",
    },
    {
      id: "why-zzeeh-weddings",
      label: "Why Zzeeh Weddings?",
    },
    {
      id: "popular-destination-locations",
      label: "Popular Destination Wedding Locations in and around Bangalore",
    },
    {
      id: "services-offered",
      label: "Zzeeh Weddings Services",
    },
    {
      id: "customization-options",
      label: "Customization Options with Zzeeh Weddings",
    },
    {
      id: "zzeeh-process",
      label: "Zzeeh Weddings: The Process",
    },
    {
      id: "case-studies",
      label: "Case Studies: Real Destination Weddings by Zzeeh",
    },
    {
      id: "why-bangalore",
      label: "Why Bangalore is a Perfect Destination for Your Wedding",
    },
    {
      id: "faqs",
      label: "Frequently Asked Questions (FAQ)",
    },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Heritage Resorts",
      body: "like the Tamarind Tree and Angsana Oasis",
    },
    {
      header: "Nature-inspired Venues",
      body: "such as farmhouses and outdoor spaces amidst greenery",
    },
    {
      header: "Palaces and Royal Venues",
      body: "for those looking for a regal and majestic celebration.",
    },
  ];

  const list2 = [
    {
      header: "Venue Selection",
      body: "Helping couples choose the perfect location.",
    },
    {
      header: "Wedding Décor",
      body: "Designing stunning, unique setups that align with the wedding theme.",
    },
    {
      header: "Photography & Videography",
      body: "Capturing every moment with expert professionals.",
    },
    {
      header: "Guest Management",
      body: "Coordinating accommodations, transportation, and ensuring guest comfort.",
    },
    {
      header: "Catering",
      body: "Collaborating with top caterers to provide a gourmet experience.",
    },
    {
      header: "Entertainment",
      body: "Booking artists and performers to keep guests entertained throughout.",
    },
    {
      header: "Bridal Styling",
      body: "Arranging for top makeup artists, stylists, and grooming experts.",
    },
    {
      header: "Logistics",
      body: "Managing travel arrangements for the couple and guests.",
    },
  ];

  const list3 = [
    {
      header: "Initial Consultation",
      body: "Understanding the couple’s preferences and style.",
    },
    {
      header: "Budget Planning",
      body: "Allocating resources effectively.",
    },
    {
      header: "Event Planning",
      body: "Covering everything from décor to guest management.",
    },
    {
      header: "On-The-Day Coordination",
      body: "to ensure the event flows smoothly and stress-free.",
    },
  ];

  const list4 = [
    {
      header: "",
      body: "A royal-themed wedding in a palace, complete with regal décor and traditional ceremonies.",
    },
    {
      header: "",
      body: "An eco-friendly farmhouse wedding, focusing on sustainability and nature-inspired aesthetics.",
    },
    {
      header: "",
      body: "A luxurious resort wedding that included a grand celebration with multiple events across several days.",
    },
  ];

  const list5 = [
    {
      header: "Pleasant Weather",
      body: "The city enjoys a moderate climate year-round, making it ideal for outdoor weddings.",
    },
    {
      header: "Blend of Tradition and Modernity",
      body: "Whether you want a traditional ceremony or a modern celebration, Bangalore has something to offer.",
    },
    {
      header: "Accessibility",
      body: "Bangalore is well-connected with domestic and international airports, making travel easy for guests.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Destination Wedding Planners in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/sb5BNjdbfxk?si=eyaaxQGcyqZhvPIt"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction to Destination Weddings
        </h1>
        <p>
          <span className="font-semibold">Destination weddings</span> are a
          growing trend, offering couples a chance to tie the knot in a
          picturesque location, away from the hustle of everyday life. These
          weddings combine celebration and vacation, making them memorable not
          only for the couple but for their guests as well. Bangalore, with its
          vibrant culture, diverse locations, and beautiful weather, has emerged
          as a favorite for{" "}
          <span className="font-semibold">
            destination Indian wedding planner
          </span>{" "}
          services.
        </p>
        <p>
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , one of the best{" "}
          <span className="font-semibold">wedding planners in Bangalore</span>,
          specializes in making these dream weddings a reality.
        </p>
      </section>

      <section id="why-zzeeh-weddings" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Zzeeh Weddings?
        </h1>
        <p>
          {" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          has established itself as a trusted name in the wedding planning
          industry, especially as{" "}
          <span className="font-semibold">destination wedding planners</span>.
          With years of experience, the team at Zzeeh understands the importance
          of personalization, attention to detail, and flawless execution.
        </p>
        <p>
          As a luxury destination wedding planner, Zzeeh goes beyond traditional
          planning by curating weddings that reflect the couple’s style,
          preferences, and personality. Their deep knowledge of Bangalore's
          venues and logistics ensures seamless execution, making them the{" "}
          <span className="font-semibold">
            best destination wedding planners
          </span>{" "}
          to partner with.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/best-places-for-destination-weddings.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="popular-destination-locations" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Popular Destination Wedding Locations in and around Bangalore
        </h1>

        <p>
          Bangalore offers a wide variety of{" "}
          <span className="font-semibold">destination wedding</span> venues,
          from luxurious resorts to serene nature-inspired locations. Some of
          the top picks include:
        </p>
        <DynamicList noColon listType="list-disc" data={list1} />
        <p>
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          , an experienced{" "}
          <span className="font-semibold">destination wedding expert</span>,
          ensures that every detail is handled seamlessly, no matter the scale
          of the wedding.
        </p>
      </section>

      <section id="services-offered" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings Services
        </h1>
        <p>
          Zzeeh offers a wide range of services tailored to meet the unique
          needs of each couple. These include:
        </p>
        <DynamicList listType="list-disc" data={list2} />
        <p>
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is regarded as one of the{" "}
          <span className="font-semibold">
            top destination wedding planners
          </span>{" "}
          for their comprehensive, end-to-end services.
        </p>
      </section>

      <section id="customization-options" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Customization Options with Zzeeh Weddings
        </h1>
        <p>
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          understands that no two weddings are alike. Whether it’s a traditional
          South Indian wedding or a lavish{" "}
          <span className="font-semibold">beach wedding company</span>{" "}
          experience, Zzeeh tailors every detail.
        </p>
        <p>
          As an experienced{" "}
          <span className="font-semibold">international wedding planner</span>,
          Zzeeh provides the flexibility to plan weddings of all sizes, from
          intimate ceremonies to grand multi-day celebrations.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Budget+Wedding+Planner+in+Bangalore/wedding-ceremony-decorations.webp"
        alt="budget-decor-ideas"
      />

      <section id="zzeeh-process" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: The Process
        </h1>
        <p>
          The team at Zzeeh follows a structured approach to planning a wedding:
        </p>
        <DynamicList noColon listType="list-disc" data={list3} />
        <p>
          With this process, Zzeeh Weddings has solidified its reputation as one
          of the leading{" "}
          <span className="font-semibold">
            wedding planning companies in Bangalore
          </span>
          .
        </p>
      </section>

      <section id="case-studies" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Case Studies: Real Destination Weddings by Zzeeh
        </h1>
        <p>Here are a few memorable weddings planned by Zzeeh:</p>
        <DynamicList noColon listType="list-disc" data={list4} />
        <p>
          These real-life examples showcase why{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          ranks on the{" "}
          <span className="font-semibold">
            list of wedding planners in Bangalore
          </span>{" "}
          as a trusted name.
        </p>
      </section>

      <section id="why-bangalore" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Bangalore is a Perfect Destination for Your Wedding
        </h1>
        <p>
          Bangalore is an excellent choice for hosting your dream wedding.
          Here’s why:
        </p>
        <DynamicList noColon listType="list-disc" data={list5} />
        <p>
          For couples seeking a blend of luxury and tradition, Zzeeh is the
          ultimate <span className="font-semibold">wedding event planner</span>.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/hanging-wedding-decors.webp"
        alt="success-stories"
      />

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQ)
        </h1>
        <p>
          Here are some common questions about planning a destination wedding in
          Bangalore with{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          :
        </p>
        <ul className={`list-disc ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              How far in advance should we plan a destination wedding?
            </p>
            <p>
              At least 6-12 months in advance to secure the best vendors and
              venues.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Can Zzeeh Weddings handle international guest management?
            </p>
            <p>
              Yes, Zzeeh, a prominent international wedding planner, assists
              with accommodations, travel, and guest services.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              What are the best locations for a beach wedding?
            </p>
            <p>
              As a top{" "}
              <span className="font-semibold">beach wedding company</span>,
              Zzeeh can help plan dreamy coastal celebrations in India and
              abroad.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Planning a destination wedding can feel overwhelming, but with Zzeeh
          Weddings, you can rest assured that every detail will be perfectly
          managed. Recognized as one of the{" "}
          <span className="font-semibold">
            best destination wedding planners
          </span>{" "}
          in Bangalore, Zzeeh offers customized solutions, flawless execution,
          and memorable celebrations.
        </p>
        <p>
          Whether you’re looking for a traditional ceremony or working with a{" "}
          <span className="font-semibold">
            luxury destination wedding planner
          </span>{" "}
          for a grand affair,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          is your perfect partner.
        </p>
        <p>
          Contact Zzeeh Weddings today to start planning your dream wedding!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
