import React from "react";
import { Dawn, GoldStar, Pendant } from "../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <section
      id="home"
      className="relative z-10 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full pt-32 pb-20"
    >
      <div className="absolute top-14 inset-0 bg-dawn bg-cover bg-center bg-no-repeat z-0"></div>
      <div className="relative z-10 flex flex-col items-center">
        <div className="flex flex-row items-center space-x-4 mb-6">
          <div className="rotate-180">
            <Pendant />
          </div>
          <h1 className="text-sm md:text-xl text-pureGold font-gt-super text-center uppercase">
            ZZEEH PRODUCTIONS
          </h1>
          <Pendant />
        </div>

        <div className="flex flex-col items-center w-[330px] md:w-[640px] text-center">
          <p className="text-dark text-3xl md:text-5xl font-medium font-made-mirage mb-4">
            Where dreams become{" "}
            <i className="text-gold font-gt-super">Unforgettable</i> memorie
            <span className="relative">
              <span className="inline-block relative">
                <span className="absolute left-[80%]">
                  <GoldStar />
                </span>
                s
              </span>
            </span>
          </p>
          <p className="text-purple text-sm md:text-xl w-[220px] md:w-[360px]">
            Your end-to-end events planner for premium experiences!
          </p>
        </div>

        <div className="mt-10 md:mt-20 lg:mt-10">
          <div className="flex flex-row justify-center items-center space-x-4">
            <Dawn />
            <p className="text-gold text-sm md:text-base font-medium uppercase text-center">
              Our Services
            </p>
            <Dawn />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mt-8 gap-10 md:gap-5 lg:gap-10">
            <div
              data-aos="zoom-out"
              data-aos-delay={200}
              className="relative cursor-pointer w-[300px] md:w-[230px] xl:w-[350px] h-[330px] md:h-[250px] xl:h-[400px] shadow-md bg-weddings bg-cover bg-center bg-no-repeat flex flex-col justify-end p-4 rounded-3xl group"
              onClick={() => navigate("/weddings")}
            >
              <div
                className="absolute inset-0 bg-gradient-to-t from-[#181418cc] to-[#18141800] rounded-3xl"
                style={{ zIndex: 1 }}
              ></div>
              <div className="relative z-10 text-xl md:text-2xl xl:text-3xl text-white p-2 flex flex-row justify-between group-hover:text-[gold] group-hover:mb-2 transition-all duration-300">
                <p className="font-medium">Weddings</p>
                <p>{`->`}</p>
              </div>
            </div>

            <div
              data-aos="zoom-out"
              data-aos-delay={400}
              className="relative cursor-pointer w-[300px] md:w-[230px] xl:w-[350px] h-[330px] md:h-[250px] xl:h-[400px] shadow-md bg-corporate bg-cover bg-center bg-no-repeat flex flex-col justify-end p-4 rounded-3xl group"
              onClick={() => window.open("https://zzeeh.com", "_blank")}
            >
              <div
                className="absolute inset-0 bg-gradient-to-t from-[#181418cc] to-[#18141800] rounded-3xl"
                style={{ zIndex: 1 }}
              ></div>
              <div className="relative z-10 text-xl md:text-2xl xl:text-3xl text-white p-2 flex flex-row justify-between group-hover:text-[gold] group-hover:mb-2 transition-all duration-300">
                <p className="font-medium">Events</p>
                <p>{`->`}</p>
              </div>
            </div>

            <div
              data-aos="zoom-out"
              data-aos-delay={600}
              className="relative cursor-pointer w-[300px] md:w-[230px] xl:w-[350px] h-[330px] md:h-[250px] xl:h-[400px] shadow-md bg-rentals bg-cover bg-center bg-no-repeat flex flex-col justify-end p-4 rounded-3xl group"
              onClick={() => toast("Coming Soon!")}
            >
              <div
                className="absolute inset-0 bg-gradient-to-t from-[#181418cc] to-[#18141800] rounded-3xl"
                style={{ zIndex: 1 }}
              ></div>
              <p className="relative z-10 text-xs text-purple self-center">
                COMING SOON
              </p>
              <div className="relative z-10 text-xl md:text-2xl xl:text-3xl text-white p-2 flex flex-row justify-between group-hover:text-[gold] group-hover:mb-2 transition-all duration-300">
                <p className="font-medium">Rentals</p>
                <p>{`->`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
