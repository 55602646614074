import React, { useState } from "react";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { customLabel } from "../../assets/styles/form";
import { toast } from "react-toastify";
import { CheckCircle } from "../../assets/icons/Icons";
import { apiClient } from "../../lib/apiClient";
import { useNavigate } from "react-router-dom";
import { emailRegex } from "../../lib/constants";

const PlanOne = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    full_name: "",
    phone_number: "",
    email: "",
    date: "",
    venue: "demo",
    no_of_events: "demo",
    no_of_guests: "demo",
    budget: "1-5 Lakh",
    comments: "",
    ticket_type: "wedding",
    advance_paid: false,
    selected_package: {
      id: "demo",
      name: "Basic Package",
      price: "₹ 5,00,000/-",
      features:
        "Includes 20ft stage backdrop, Mantap, Pathway flower decor, Lawn entrance arch, Welcome board, Garlands, Car decor and much more!",
    },
  });

  const resetState = () => {
    setFormState({
      full_name: "",
      phone_number: "",
      email: "",
      date: "",
      venue: "",
      no_of_events: "1",
      no_of_guests: "20 to 50",
      budget: "1-5 Lakh",
      comments: "",
      ticket_type: "wedding",
      advance_paid: false,
      selected_package: {
        id: "basic",
        name: "Basic Package",
        price: "₹ 5,00,000/-",
        features:
          "Includes 20ft stage backdrop, Mantap, Pathway flower decor, Lawn entrance arch, Welcome board, Garlands, Car decor and much more!",
      },
    });
  };

  const validateForm = () => {
    const { full_name, phone_number, email, date, budget } = formState;

    if (!full_name) {
      toast.error("Full name is required");
      return false;
    }
    if (!phone_number) {
      toast.error("Phone number is required");
      return false;
    }
    if (!email) {
      toast.error("Email is required");
      return false;
    }
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return false;
    }
    if (
      isNaN(Number(phone_number)) ||
      phone_number.length !== 10 ||
      !/^[6-9]/.test(phone_number)
    ) {
      toast.error("Invalid phone number");
      return false;
    }
    if (!date) {
      toast.error("Wedding date is required");
      return false;
    }
    if (!budget) {
      toast.error("Budget is required");
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        setLoading(true);

        const { selected_package, ...otherFields } = formState;
        const payload = {
          ...otherFields,
          package_id: selected_package.id,
        };

        // Send data to your backend
        const res = await apiClient.post(`/open/create-ticket`, payload);

        if (res?.status === 201) {
          toast.success("Submitted successfully!");
          resetState();
          setStep(2);

          try {
            // Send data to HubSpot
            const hubspotPayload = {
              fields: [
                { name: "full_name", value: formState.full_name },
                { name: "phone", value: formState.phone_number },
                { name: "email", value: formState.email },
                { name: "wedding_date", value: formState.date },
                { name: "message", value: formState.comments },
              ],
              context: {
                pageUri: window.location.href,
                pageName: document.title,
              },
            };

            // HubSpot Forms API endpoint
            const hubspotResponse = await fetch(
              `https://api.hsforms.com/submissions/v3/integration/submit/47671733/69074079-24fc-4837-98ef-45ec4cc43ad2`,

              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(hubspotPayload),
              }
            );

            if (hubspotResponse.ok) {
              console.log("HubSpot Success");
              navigate("/thank_you");
            }
          } catch (hubspotError) {
            console.error("HubSpot Submission Error:", hubspotError);
          }
        } else {
          console.log(res);
          toast.error("Unknown error occurred!");
        }
      } catch (backendError) {
        console.error("Backend Submission Error:", backendError);
        toast.error("Failed to submit the form. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section
      id="form"
      className="relative z-10 py-10 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="absolute inset-0 bg-dawn bg-cover bg-center bg-no-repeat z-0"></div>

      <div className="flex flex-col items-center">
        <p className="text-dark text-3xl md:text-[40px] font-medium font-made-mirage text-center">
          Let's plan your <i className="text-gold font-gt-super">special </i>
          day!
        </p>
        <p className="text-purple text-sm md:text-xl mt-4 md:w-[600px] text-center">
          Fill this form to help us understand your needs. We will reach out to
          you for further discussions.
        </p>

        <form
          className="flex flex-col items-center p-5 md:p-10 bg-[#A18DA31A] border border-[#5A495B80] opacity-100 rounded-3xl mt-10 z-10 w-full md:w-[640px]"
          onSubmit={handleSubmit}
        >
          {step === 1 ? (
            <>
              <p className="text-2xl font-made-mirage font-medium text-center">
                Schedule a Free Consultation
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 mt-12 w-full">
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="name"
                      value="Full Name"
                      theme={customLabel}
                    />
                    <span className="text-gold">*</span>
                  </div>
                  <TextInput
                    id="name"
                    type="text"
                    value={formState.full_name}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        full_name: e.target.value,
                      }))
                    }
                    placeholder="John Doe"
                  />
                </div>

                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="phno"
                      value="Phone Number"
                      theme={customLabel}
                    />
                    <span className="text-gold">*</span>
                  </div>
                  <TextInput
                    id="phno"
                    type="text"
                    value={formState.phone_number}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        phone_number: e.target.value,
                      }))
                    }
                    placeholder="9999999999"
                    addon="+91"
                    maxLength={10}
                  />
                </div>

                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email" value="Email" theme={customLabel} />
                    <span className="text-gold">*</span>
                  </div>
                  <TextInput
                    id="email"
                    type="text"
                    value={formState.email}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        email: e.target.value.toLocaleLowerCase(),
                      }))
                    }
                    placeholder="example@gmail.com"
                  />
                </div>

                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="date"
                      value="Wedding Date"
                      theme={customLabel}
                    />
                    <span className="text-gold">*</span>
                  </div>
                  <TextInput
                    id="date"
                    type="date"
                    placeholder="dd-mm-yyyy"
                    value={formState.date}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        date: e.target.value,
                      }))
                    }
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>

              <div className="self-start mt-3 md:mt-6">
                <div className="mb-2 block">
                  <Label
                    htmlFor="budget"
                    value="Your Budget (₹)"
                    theme={customLabel}
                  />
                  <span className="text-gold">*</span>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                  <Button
                    color="light"
                    className={`${
                      formState.budget === "1-5 Lakh"
                        ? "bg-black text-white"
                        : "bg-white"
                    } hover:!bg-[#5A495B80] hover:!text-white rounded-full`}
                    type="button"
                    onClick={() =>
                      setFormState((prevState) => ({
                        ...prevState,
                        budget: "1-5 Lakh",
                      }))
                    }
                  >
                    1-5 Lakh
                  </Button>
                  <Button
                    color="light"
                    className={`${
                      formState.budget === "5-10 Lakh"
                        ? "bg-black text-white"
                        : "bg-white"
                    } hover:!bg-[#5A495B80] hover:!text-white rounded-full`}
                    type="button"
                    onClick={() =>
                      setFormState((prevState) => ({
                        ...prevState,
                        budget: "5-10 Lakh",
                      }))
                    }
                  >
                    5-10 Lakh
                  </Button>
                  <Button
                    color="light"
                    className={`${
                      formState.budget === "10-20 Lakh"
                        ? "bg-black text-white"
                        : "bg-white"
                    } hover:!bg-[#5A495B80] hover:!text-white rounded-full`}
                    type="button"
                    onClick={() =>
                      setFormState((prevState) => ({
                        ...prevState,
                        budget: "10-20 Lakh",
                      }))
                    }
                  >
                    10-20 Lakh
                  </Button>
                  <Button
                    color="light"
                    className={`${
                      formState.budget === "20+ Lakh"
                        ? "bg-black text-white"
                        : "bg-white"
                    } hover:!bg-[#5A495B80] hover:!text-white rounded-full`}
                    type="button"
                    onClick={() =>
                      setFormState((prevState) => ({
                        ...prevState,
                        budget: "20+ Lakh",
                      }))
                    }
                  >
                    20+ Lakh
                  </Button>
                </div>
              </div>

              <div className="self-start w-full mt-3 md:mt-6">
                <div className="mb-2 block">
                  <Label
                    htmlFor="comments"
                    value="Additional details"
                    theme={customLabel}
                  />
                </div>
                <Textarea
                  id="comments"
                  value={formState.comments}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      comments: e.target.value,
                    }))
                  }
                  placeholder="Add any details about the wedding that we may find helpful"
                  rows={4}
                  className="resize-none"
                />
              </div>
              <Button
                className="relative bg-black hover:!bg-gray-800 focus:ring-0 mt-8 group"
                type="submit"
              >
                {loading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                      ></path>
                    </svg>
                    Submiting...
                  </>
                ) : (
                  <>
                    Submit
                    <span className="ml-1 inline-block transition-transform duration-300 transform group-hover:translate-x-1">{`->`}</span>
                  </>
                )}
              </Button>
            </>
          ) : (
            <div className="flex flex-col items-center">
              <CheckCircle />
              <p className="text-2xl md:text-3xl font-made-mirage font-medium mt-6">
                Form has been submitted!
              </p>
              <p className="md:text-xl text-purple mt-3 text-center">
                We will reach out to you within the next 24-48 hours to plan
                your occasion.
              </p>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default PlanOne;
