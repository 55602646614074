import React, { useEffect, useState } from "react";
import { YellowStar } from "../../assets/icons/Icons";
import Marquee from "react-marquee-slider";
import times from "lodash/times";
import test1 from "../../assets/images/testimonials/test1.webp";
import test2 from "../../assets/images/testimonials/test2.webp";
import test3 from "../../assets/images/testimonials/test3.webp";
import test4 from "../../assets/images/testimonials/test4.webp";
import test5 from "../../assets/images/testimonials/test5.webp";
import test6 from "../../assets/images/testimonials/test6.webp";
import test7 from "../../assets/images/testimonials/test7.webp";
import test8 from "../../assets/images/testimonials/test8.webp";
import test9 from "../../assets/images/testimonials/test9.webp";
import test10 from "../../assets/images/testimonials/test10.webp";
import test11 from "../../assets/images/testimonials/test11.webp";
import GalleryModal from "../modals/GalleryModal";

// Example image array, replace with actual image paths
const images1 = [test1, test2, test3, test4, test5];

const images2 = [test6, test7, test8, test9, test10, test11];

const Testimonials = () => {
  const [velocity, setVelocity] = useState(30);
  const [images, setImages] = useState<Array<string>>([""]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPreviousImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const updateVelocity = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setVelocity(5); // Mobile
      } else if (width < 1024) {
        setVelocity(10); // Tablet
      } else if (width < 1440) {
        setVelocity(20); // Desktop
      } else {
        setVelocity(30);
      }
    };

    updateVelocity();
    window.addEventListener("resize", updateVelocity);
    return () => window.removeEventListener("resize", updateVelocity);
  }, []);

  return (
    <section
      id="zzeeh-test"
      className="pt-10 pb-16 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="flex flex-col items-center space-y-10">
        <div className="flex flex-col items-center space-y-3">
          <div className="flex flex-row items-center space-x-5">
            <YellowStar />
            <p className="text-3xl lg:text-[40px] font-made-mirage font-medium">
              Testimonials
            </p>
            <YellowStar />
          </div>
          <p className="text-purple text-sm lg:text-xl text-center">
            See What Our Happy Customers Are Saying About Their Experiences!
          </p>
        </div>

        <div>
          {/* Top scrolling div - right to left */}
          <div className="relative w-full overflow-hidden mb-5">
            <div className="absolute inset-y-0 left-0 w-24 bg-gradient-to-r from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"></div>
            <div className="absolute inset-y-0 right-0 w-24 bg-gradient-to-l from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"></div>
            <div className="relative z-10">
              <Marquee
                scatterRandomly={false}
                resetAfterTries={5}
                onInit={() => {}}
                onFinish={() => {}}
                velocity={velocity}
                direction="rtl"
              >
                {times(10, Number).map((id) => (
                  <div key={`marquee-top-${id}`} className="mx-2">
                    <img
                      src={images1[id % images1.length]}
                      alt={`test ${id}`}
                      className="w-96 h-64 object-cover rounded-lg hover:cursor-pointer"
                      onClick={() => {
                        setImages(images1);
                        setIsModalOpen(true);
                      }}
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>

          {/* Bottom scrolling div - left to right */}
          <div className="relative w-full overflow-hidden">
            <div className="absolute inset-y-0 left-0 w-24 bg-gradient-to-r from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"></div>
            <div className="absolute inset-y-0 right-0 w-24 bg-gradient-to-l from-[#FCFBFA] via-[#FCFBFA40] to-[#FCFBFA00] z-20"></div>
            <div className="relative z-10">
              <Marquee
                scatterRandomly={false}
                resetAfterTries={5}
                onInit={() => {}}
                onFinish={() => {}}
                velocity={velocity}
                direction="ltr"
              >
                {times(10, Number).map((id) => (
                  <div key={`marquee-bottom-${id}`} className="mx-2">
                    <img
                      src={images2[id % images2.length]}
                      alt={`test ${id}`}
                      className="w-96 h-64 object-cover rounded-lg hover:cursor-pointer"
                      onClick={() => {
                        setImages(images2);
                        setIsModalOpen(true);
                      }}
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-3 mt-10 w-[90%] xl:w-[80%]">
          <p className="text-purple text-sm lg:text-xl text-center">
            Zzeeh Weddings has earned prestigious awards not only for its
            efficiency but also for its exceptional creativity. Every wedding is
            unique because every dream is unique. We are meticulous and
            attentive, ensuring that nothing is overlooked and every detail on
            your checklist is perfectly executed—so the only thing left for you
            to do is say, "I do!"
          </p>
          <p className="text-purple text-sm lg:text-xl text-center">
            As a highly experienced Wedding Planner in Bangalore, we guarantee
            that your wedding will stand out from the rest. We focus on even the
            smallest yet most significant beauty-oriented details, ensuring a
            truly unforgettable celebration.
          </p>
        </div>
      </div>

      <GalleryModal
        isOpen={isModalOpen}
        images={images}
        currentIndex={currentIndex}
        onClose={() => setIsModalOpen(false)}
        onNext={goToNextImage}
        onPrevious={goToPreviousImage}
      />
    </section>
  );
};

export default Testimonials;
