import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { navItems } from "../../assets/data/navbar";
import CustomToggle from "./CustomToggle";
import ScrollToTop from "./ScrollToTop";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/images/logos/zzeeh_logo2.webp";
import whatsapp_logo from "../../assets/images/logos/zzeeh.jpg";
import CTAButton from "./CTAButton";
import FormModalOne from "../MainPage/FormModalOne";

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalVisible, setModalVisible] = useState(false);
  const [currentNav, setCurrentNav] = useState("");
  const [isTogglerOpen, setTogglerOpen] = useState(false);

  const handleTogglerClick = () => {
    setTogglerOpen(!isTogglerOpen);
  };

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setCurrentNav(path);
  }, [location]);

  return (
    <nav
      id="NavMain"
      className="bg-white px-3 py-2.5 dark:border-gray-700 dark:bg-gray-800 sm:px-4 fixed top-0 w-full z-50 rounded-none shadow-md"
    >
      <div className="container mx-auto flex md:flex-wrap items-center justify-between">
        <a
          href="/"
          className="flex items-center mx-2"
          onClick={() => {
            navigate(`/`);
            setCurrentNav("home");
            const elementToView = document.getElementById("home");
            elementToView?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          <img
            src={logo}
            className="mr-2 md:mr-3 h-9 cursor-pointer rounded-md"
            alt="Zzeeh Logo"
          />
        </a>

        <div className="hidden xl:flex items-center space-x-8">
          {navItems.map((item, index) => (
            <div key={index} className="relative">
              <a
                href={`/${item.link}`}
                onClick={() => setCurrentNav(item.link)}
                className={`flex items-center md:hover:!text-[gold] text-lg font-medium transition-colors ease-in-out duration-300 ${
                  currentNav === item.link ? "!text-gold" : "!text-black"
                }`}
              >
                {item.name}
              </a>
            </div>
          ))}
        </div>

        <CTAButton
          name="Schedule Free Consultation"
          handleClick={() => setModalVisible(true)}
        />

        <div className="flex xl:hidden items-center space-x-4">
          {/* Custom toggle button */}
          <CustomToggle isOpen={isTogglerOpen} onClick={handleTogglerClick} />
        </div>
      </div>

      {/* Mobile Menu */}
      {isTogglerOpen && (
        <div className="block xl:hidden pt-5">
          {navItems.map((item, index) => (
            <div
              key={index}
              className={`${
                navItems.length === index + 1 ? "" : "border-b"
              } flex flex-col items-center`}
            >
              <div className="flex flex-row justify-between w-full p-2">
                <a
                  href={`/${item.link}`}
                  onClick={() => setCurrentNav(item.link)}
                  className={`font-medium cursor-pointer md:hover:!text-[gold] transition-colors ease-in-out duration-300 ${
                    currentNav === item.link ? "!text-gold" : "!text-black"
                  }`}
                >
                  {item.name}
                </a>
              </div>
            </div>
          ))}
        </div>
      )}

      <ScrollToTop />

      <FormModalOne
        modalIsOpen={modalVisible}
        closeModal={() => setModalVisible(false)}
      />

      <FloatingWhatsApp
        phoneNumber="9110466643"
        accountName="Zzeeh Productions"
        statusMessage="Online"
        avatar={whatsapp_logo}
        chatMessage="Discover the magic of effortless planning with Zzeeh Productions!"
        messageDelay={1}
        allowEsc
        allowClickAway
        notification
      />
    </nav>
  );
};

export default NavigationBar;
