import React from "react";
import NavigationBar from "../../components/ui/Navbar";
import Footer from "../../components/ui/Footer";

const Terms = () => {
  return (
    <div className="flex flex-col items-center">
      <NavigationBar />

      <div className="px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full py-20 md:py-32 text-justify">
        <p className="self-center whitespace-nowrap text-3xl font-semibold">
          Terms of Use
        </p>
        <p>Last Updated on August 28th, 2024</p>
        <p className="mt-5">
          These Terms of Use ("Terms") govern your access to and use of the
          services provided by Zzeeh Productions Pvt Ltd ("Zzeeh," "we," "us,"
          or "our"). By accessing or using our website, mobile applications, and
          other online services (collectively, the "Services"), you agree to be
          bound by these Terms. Please read them carefully before using our
          Services:
        </p>
        <div className="lg:ml-14 ml-8 mt-2">
          <ul className="list-decimal">
            <li className="mt-5">
              <p className="font-bold">User Eligibility:</p>
              <p>
                You must be at least 18 years old and have the legal capacity to
                enter into a contract to use our Services. By using our
                Services, you represent and warrant that you meet these
                eligibility requirements.
              </p>
            </li>
            <li className="mt-5">
              <p className="font-bold">Registration and Account:</p>
              <p>
                To access certain features of our Services, you may need to
                register for an account. When registering, you must provide
                accurate and complete information. You are responsible for
                maintaining the confidentiality of your account credentials and
                for all activities that occur under your account. You agree to
                notify us immediately of any unauthorized use of your account or
                any other breach of security.
              </p>
            </li>
            <li className="mt-5">
              <p className="font-bold">Use of the Services:</p>
              <ul className="list-disc ml-5">
                <li>
                  <p>
                    <span className="font-bold">Personal Use:</span> Our
                    Services are intended for personal and non-commercial use
                    only. You may not use our Services for any commercial
                    purposes without our prior written consent.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="font-bold">Compliance with Laws:</span> You
                    agree to use our Services in compliance with all applicable
                    laws, regulations, and third-party rights.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="font-bold">Prohibited Activities:</span>{" "}
                    You are prohibited from engaging in any activities that may:
                  </p>
                  <ul className="list-disc ml-5">
                    <li>
                      <p>
                        Violate these Terms or any other applicable agreements
                        or policies;
                      </p>
                    </li>
                    <li>
                      <p>
                        Infringe upon the intellectual property or other
                        proprietary rights of others;
                      </p>
                    </li>
                    <li>
                      <p>
                        Interfere with or disrupt the operation of our Services
                        or the servers or networks connected to our Services;
                      </p>
                    </li>
                    <li>
                      <p>
                        Introduce viruses, malware, or any other harmful code;
                      </p>
                    </li>
                    <li>
                      <p>
                        Collect or harvest personal information of other users
                        without their consent;
                      </p>
                    </li>
                    <li>
                      <p>
                        Engage in any fraudulent, deceptive, or unlawful
                        activities.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mt-5">
              <p className="font-bold">Intellectual Property:</p>
              <ul className="list-disc ml-5">
                <li>
                  <p>
                    <span className="font-bold">Ownership:</span> The content,
                    features, and functionality of our Services, including but
                    not limited to text, graphics, logos, images, and software,
                    are owned by Zzeeh or our licensors and are protected by
                    copyright, trademark, and other intellectual property laws.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="font-bold">Limited License:</span> Subject
                    to your compliance with these Terms, we grant you a limited,
                    non-exclusive, non-transferable, and revocable license to
                    access and use our Services for personal, non-commercial
                    purposes.
                  </p>
                </li>
              </ul>
            </li>
            <li className="mt-5">
              <p className="font-bold">Privacy:</p>
              <p>
                Your use of our Services is subject to our Privacy Policy, which
                explains how we collect, use, share, and protect your personal
                information. By using our Services, you consent to the
                collection and use of your personal information as described in
                the Privacy Policy.
              </p>
            </li>
            <li className="mt-5">
              <p className="font-bold">Limitation of Liability:</p>
              <p>
                To the maximum extent permitted by applicable law, Zzeeh and its
                affiliates, directors, officers, employees, agents, and
                licensors shall not be liable for any indirect, incidental,
                special, consequential, or punitive damages, including but not
                limited to damages for loss of profits, goodwill, use, data, or
                other intangible losses, arising out of or in connection with
                your use of our Services.
              </p>
            </li>
            <li className="mt-5">
              <p className="font-bold">Modifications to the Services:</p>
              <p>
                We reserve the right to modify or discontinue our Services,
                temporarily or permanently, with or without notice. You agree
                that we shall not be liable to you or any third party for any
                modification, suspension, or discontinuation of our Services.
              </p>
            </li>
            <li className="mt-5">
              <p className="font-bold">Governing Law and Jurisdiction:</p>
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws of [Jurisdiction]. Any disputes arising out of or
                in connection with these Terms shall be subject to the exclusive
                jurisdiction of the courts of [Jurisdiction].
              </p>
            </li>
            <li className="mt-5">
              <p className="font-bold">Entire Agreement:</p>
              <p>
                These Terms, together with our Privacy Policy, constitute the
                entire agreement between you and Zzeeh concerning your use of
                our Services and supersede any prior or contemporaneous
                agreements, communications, or understandings.
              </p>
            </li>
            <li className="mt-5">
              <p className="font-bold">Contact Us:</p>
              <p>
                If you have any questions or concerns about these Terms or our
                Services, please contact us at{" "}
                <a
                  href="mailto:info@zzeeh.com"
                  className="underline text-blue-600"
                >
                  info@zzeeh.com
                </a>
                .
              </p>
            </li>
          </ul>
        </div>
        <p className="mt-5">
          By accessing or using our Services, you acknowledge that you have
          read, understood, and agreed to be bound by these Terms of Use.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
