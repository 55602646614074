import React, { useEffect } from "react";
import { Close, LeftArrow, RightArrow } from "../../assets/icons/Icons";

interface GalleryModalProps {
  isOpen: boolean;
  images: string[];
  currentIndex: number;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

const GalleryModal: React.FC<GalleryModalProps> = ({
  isOpen,
  images,
  currentIndex,
  onClose,
  onNext,
  onPrevious,
}) => {
  // Handle keyboard navigation
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") onNext();
      else if (event.key === "ArrowLeft") onPrevious();
      else if (event.key === "Escape") onClose();
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, onNext, onPrevious, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-white flex flex-col items-center justify-center z-50">
      {/* Close Button */}
      <button
        className="absolute top-5 right-5 text-[32px] leading-10 text-dark hover:opacity-90"
        onClick={onClose}
      >
        <Close />
      </button>

      {/* Previous Arrow */}
      <button
        className="absolute left-2 lg:left-5 top-1/2 transform -translate-y-1/2 font-bold text-dark hover:opacity-90 p-2"
        onClick={onPrevious}
      >
        <LeftArrow fill="fill-white lg:fill-black" />
      </button>

      {/* Content */}
      <img
        src={images[currentIndex]}
        alt={`Gallery ${currentIndex}`}
        className={`w-full object-contain max-h-screen`}
      />

      {/* Next Arrow */}
      <button
        className="absolute right-2 lg:right-5 top-1/2 transform -translate-y-1/2 font-bold text-dark hover:opacity-90 p-2"
        onClick={onNext}
      >
        <RightArrow fill="fill-white lg:fill-black" />
      </button>
    </div>
  );
};

export default GalleryModal;
