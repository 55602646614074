import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "zzeeh-weddings-guide",
      label:
        "Zzeeh Weddings: Your Ultimate Guide to Affordable Wedding Planning in Bangalore",
    },
    {
      id: "cost-wedding-planner",
      label: "Cost of Wedding Planner: Affordable Options for Every Couple",
    },
    {
      id: "cheap-wedding-decoration",
      label: "Cheap Wedding Decoration Packages for Every Style",
    },
    {
      id: "low-budget-photography",
      label: "Low Budget Wedding Photography and Videography",
    },
    {
      id: "affordable-catering-budget",
      label: "Affordable Wedding Catering and Average Catering Budget Wedding",
    },
    {
      id: "event-planner-cost",
      label: "Wedding Event Planner Cost: Transparent and Affordable Pricing",
    },
    {
      id: "stage-decoration-solutions",
      label: "High Budget Wedding Stage Decoration to Low Budget Solutions",
    },
    {
      id: "budget-wedding-organizer",
      label: "Budget Wedding Organizer: Tailored to Your Needs",
    },
    {
      id: "why-choose-zzeeh",
      label: "Why Choose Zzeeh Weddings for Your Budget-Friendly Wedding?",
    },
    {
      id: "make-dream-wedding",
      label: "Make Your Dream Wedding a Reality with Zzeeh Weddings",
    },
  ];

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Customized Solutions",
      body: "Whether you're looking for low budget wedding stage decoration or affordable wedding catering, we tailor our services to your specific needs and budget.",
    },
    {
      header: "Expert Coordination",
      body: "Our experienced team of budget wedding organizers will handle all aspects of your wedding, ensuring everything is perfectly planned and executed.",
    },
    {
      header: "Affordable Packages",
      body: "From budget wedding photography to low budget wedding mandap decoration, we offer comprehensive packages that cover all your wedding needs at affordable prices.",
    },
    {
      header: "Local Expertise",
      body: "Looking for low budget wedding stage decoration near me? We have deep experience working with local venues and suppliers to ensure that your wedding day is both beautiful and budget-friendly.",
    },
    {
      header: "Stress-Free Planning",
      body: "With Zzeeh Weddings, you can rest easy knowing that every detail, from your wedding event planner cost to your décor, is taken care of.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Budget Wedding Planner in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/OYWfA-3v0Cw?si=wgp6V0tpOw7oC_Cm"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="zzeeh-weddings-guide" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: Your Ultimate Guide to Affordable Wedding Planning in
          Bangalore
        </h1>
        <p>
          Planning a wedding can be an exciting yet overwhelming experience,
          especially when you want to make it memorable without burning a hole
          in your wallet.{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , a leading{" "}
          <span className="font-semibold">budget wedding planner</span> in
          Bangalore, specializes in creating beautiful weddings at an affordable
          cost. Whether you're seeking{" "}
          <span className="font-semibold">
            cheap wedding decoration packages, low budget wedding photography
          </span>
          , or <span className="font-semibold">budget-friendly catering</span>,
          we offer tailored solutions to help you stay within your budget while
          ensuring your wedding is nothing short of spectacular.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Budget+Wedding+Planner+in+Bangalore/destination-wedding-packages-with-prices.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="cost-wedding-planner" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Cost of Wedding Planner: Affordable Options for Every Couple
        </h1>
        <p>
          One of the first questions couples often ask is, "What is the{" "}
          <span className="font-semibold">cost of wedding planner</span>{" "}
          services?" At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we provide{" "}
          <span className="font-semibold">affordable wedding planning</span>{" "}
          that fits various budgets. Our services are designed to ensure that
          you get the most value for your money without compromising on quality.
          From the initial consultation to the final execution, we work with you
          to create a personalized plan that meets your needs and financial
          constraints.
        </p>
        <p>
          We understand that wedding planners can often be seen as a costly
          investment, but we believe that{" "}
          <span className="font-semibold">
            affordable wedding planners near me
          </span>{" "}
          can still offer top-notch service. Our team is committed to making
          your dream wedding a reality, whether it's a grand celebration or a
          more intimate affair. With{" "}
          <span className="font-semibold">
            low budget wedding planners in Bangalore
          </span>
          , you'll enjoy a stress-free experience without breaking the bank.
        </p>
      </section>

      <section id="cheap-wedding-decoration" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Cheap Wedding Decoration Packages for Every Style
        </h1>
        <p>
          Wedding decoration plays a key role in setting the tone of your big
          day. If you're searching for{" "}
          <span className="font-semibold">
            cheap wedding decoration packages
          </span>{" "}
          that don't compromise on style or quality,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is the solution. We offer a variety of{" "}
          <span className="font-semibold">budget wedding stage decoration</span>{" "}
          options, including low budget marriage stage decoration and low budget
          wedding mandap decoration, designed to create a stunning atmosphere
          for your ceremony and reception.
        </p>
        <p>
          From traditional to modern, our team can design and execute beautiful
          wedding décor that fits within your budget. If you're looking for{" "}
          <span className="font-semibold">
            low budget wedding stage decoration near me
          </span>
          , we provide local, affordable solutions tailored to your venue and
          wedding theme. You can rest assured that your decorations will make a
          lasting impression, without the high price tag.
        </p>
      </section>

      <section id="low-budget-photography" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Low Budget Wedding Photography and Videography
        </h1>
        <p>
          Capturing your special day is essential, but wedding photography and
          videography can often take up a significant portion of your budget. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we offer{" "}
          <span className="font-semibold">low budget wedding photography</span>{" "}
          and <span className="font-semibold">budget wedding videography</span>{" "}
          packages that still provide high-quality results. Our photographers
          and videographers are experts at capturing every moment, ensuring you
          have beautiful memories to cherish for years to come—without exceeding
          your budget.
        </p>
        <p>
          If you’re looking for the best value in{" "}
          <span className="font-semibold">pre wedding budget</span> photography
          or videography, we can create a package that meets your needs and
          gives you a polished, professional result.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Budget+Wedding+Planner+in+Bangalore/flower-mandap-decoration.webp"
        alt="budget-decor-ideas"
      />

      <section id="affordable-catering-budget" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Affordable Wedding Catering and Average Catering Budget Wedding
        </h3>
        <p>
          Food is one of the most important aspects of any wedding celebration.
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we specialize in{" "}
          <span className="font-semibold">budget wedding catering</span> that
          delivers quality cuisine at affordable prices. Whether you're hosting
          an elaborate reception or a simple gathering, we can provide{" "}
          <span className="font-semibold">affordable wedding catering</span>{" "}
          packages that suit your needs.
        </p>
        <p>
          The{" "}
          <span className="font-semibold">average catering budget wedding</span>{" "}
          depends on the number of guests, the type of food, and the style of
          service. We work with you to create a catering plan that fits your
          financial situation while ensuring that your guests enjoy delicious
          food throughout the event.
        </p>
      </section>

      <section id="event-planner-cost" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Event Planner Cost: Transparent and Affordable Pricing
        </h3>
        <p>
          Wedding planning is a detailed and time-consuming task. The{" "}
          <span className="font-semibold">wedding event planner cost</span> can
          vary depending on the level of service you require. However, with{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , you can expect clear and transparent pricing. We offer customizable
          packages that allow you to pick and choose services based on your
          needs, ensuring that you're only paying for what you need. Whether
          you're looking for full-service planning or help with specific
          elements, we provide options that fit every budget.
        </p>
      </section>

      <section id="stage-decoration-solutions" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          High Budget Wedding Stage Decoration to Low Budget Solutions
        </h1>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we cater to both high-end and budget weddings. If you're looking for{" "}
          <span className="font-semibold">
            high budget wedding stage decoration
          </span>
          , we can deliver luxurious and opulent designs that will wow your
          guests. On the other hand, if you’re more budget-conscious, we provide{" "}
          <span className="font-semibold">
            low budget wedding stage decoration
          </span>{" "}
          that still achieves a stunning look. No matter your budget, our expert
          decorators will create a stage that reflects your personal style and
          wedding theme.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Budget+Wedding+Planner+in+Bangalore/wedding-ceremony-decorations.webp"
        alt="success-stories"
      />

      <section id="budget-wedding-organizer" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget Wedding Organizer: Tailored to Your Needs
        </h1>
        <p>
          Our role as your{" "}
          <span className="font-semibold">budget wedding organizer</span> goes
          beyond coordinating the event on the day. We are here to assist with
          every step of the planning process, from venue selection and décor to
          catering and entertainment. Our team will guide you through every
          decision to ensure your wedding runs smoothly and fits within your{" "}
          <span className="font-semibold">
            low budget wedding stage decoration price
          </span>
          .
        </p>
      </section>

      <section id="why-choose-zzeeh" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings for Your Budget-Friendly Wedding?
        </h1>
        <DynamicList listType="list-disc" data={list1} />
      </section>

      <section id="make-dream-wedding" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Make Your Dream Wedding a Reality with Zzeeh Weddings
        </h1>
        <p>
          You deserve a wedding that’s both beautiful and affordable. With{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we prove that{" "}
          <span className="font-semibold">affordable wedding planners</span> can
          deliver stunning, personalized weddings within your budget. From{" "}
          <span className="font-semibold">
            cheap wedding decoration packages
          </span>{" "}
          to{" "}
          <span className="font-semibold">low budget wedding photography</span>,
          we offer a range of services that help make your dream wedding come
          true without breaking the bank.
        </p>
        <p>
          If you're looking for the perfect{" "}
          <span className="font-semibold">budget wedding planner</span> in
          Bangalore, look no further than{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          . Let us help you create a day that you and your guests will remember
          forever—without the high costs. Contact us today to discuss your
          wedding plans and explore our affordable options!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
