import React, { useEffect, useState } from "react";
import NavigationBar from "../components/ui/Navbar";
import Footer from "../components/ui/Footer";
import Hero from "../components/Weddings/Hero";
import PlanOne from "../components/Weddings/PlanOne";
import ZzeehGallery from "../components/MainPage/ZzeehGallery";
import FAQs from "../components/MainPage/FAQs";
import { wedding } from "../assets/data/faqs";
import WhyChooseUs from "../components/Weddings/WhyChooseUs";
import FormModalOne from "../components/MainPage/FormModalOne";
import WeddingVideos from "../components/Weddings/WeddingVideos";
import Testimonials from "../components/MainPage/Testimonials";
import TimelessWeddingMagic from "../components/Weddings/TimelessWeddingMagic";

const WeddingsPage = () => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    var elementToView = document.getElementById("hero");
    elementToView?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className="flex flex-col items-center">
      <NavigationBar />
      <Hero setVisible={setModalVisible} />
      <PlanOne />
      <TimelessWeddingMagic />
      <ZzeehGallery
        name="Weddings"
        text="Discover our breathtaking creations and unforgettable moments!"
      />
      <WhyChooseUs setVisible={setModalVisible} />
      <WeddingVideos />
      <Testimonials />
      <FAQs faqs={wedding} />
      <Footer />

      <FormModalOne
        modalIsOpen={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
    </div>
  );
};

export default WeddingsPage;
