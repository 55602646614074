import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { emailRegex } from "../lib/constants";
import { apiClient } from "../lib/apiClient";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { customLabel } from "../assets/styles/form";
import { CheckCircle, Shield } from "../assets/icons/Icons";
import { formatTime } from "../lib/funcs";
import useRazorpay, { RazorpayOptions } from "react-razorpay";

const ClassForm = () => {
  const [Razorpay] = useRazorpay();

  const [step, setStep] = useState(1);
  const [timeLeft, setTimeLeft] = useState<number>(600);
  const [totalPrice, setTotalPrice] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    full_name: "",
    phone_number: "",
    email: "",
    why_wedding_planner: "",
  });

  // Calculate tax based on the current total price
  const tax = (totalPrice * 0.18).toFixed(2);

  // Calculate total with tax
  const total = Math.round(totalPrice + Number(tax));

  // Razorpay total (in paise)
  const razorpay_total = String(total * 100);

  const resetState = () => {
    setFormState({
      full_name: "",
      phone_number: "",
      email: "",
      why_wedding_planner: "",
    });
  };

  const validateForm = () => {
    const { full_name, phone_number, email, why_wedding_planner } = formState;

    if (!full_name) {
      toast.error("Full name is required");
      return false;
    }
    if (!phone_number) {
      toast.error("Phone number is required");
      return false;
    }
    if (!email) {
      toast.error("Email is required");
      return false;
    }
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return false;
    }
    if (!why_wedding_planner) {
      toast.error("Please answer the question");
      return false;
    }
    if (why_wedding_planner.trim().split(" ").length < 5) {
      toast.error("Please provide a complete answer with at least five words.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (payment_id: string) => {
    if (validateForm()) {
      try {
        setLoading(true);

        const payload = {
          ...formState,
          payment_id,
          amount: razorpay_total,
          date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
          class_type: "2ndApril_2h",
        };

        const res = await apiClient.post(`/open/academy-registration`, payload);

        if (res?.status === 201) {
          toast.success("Registered successfully!");
          resetState();
          setStep(3);
        } else {
          console.log(res);
          toast.error("Unknown error occurred!");
        }
      } catch (backendError) {
        console.error("Backend Submission Error:", backendError);
        toast.error("Failed to submit the form. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePayment = useCallback(async () => {
    try {
      // Make the request to your backend to create a payment order
      const data = await apiClient.post(`/open/academy-pay`, {
        amount: razorpay_total,
      });

      if (data?.status !== 200) {
        throw new Error("Failed to create Razorpay order.");
      }

      const orderID = data.data.id;

      const options: RazorpayOptions = {
        key: "rzp_live_ak4WUtB7XVtLLm",
        amount: razorpay_total,
        currency: "INR",
        name: "Zzeeh Productions",
        description: "Your end-to-end events planner for premium experiences!",
        image:
          "https://zzeeh.s3.ap-south-1.amazonaws.com/website/logos/logo.png",
        order_id: orderID,
        handler: async (response) => {
          try {
            console.log(response);
            // Notify backend about the successful payment (you can skip this part if webhook does it automatically)
            const verifyResponse = await apiClient.post(
              "/open/academy-pay-verify",
              {
                ...response,
                full_name: formState.full_name,
                email: formState.email,
                phone_number: formState.phone_number,
                total: razorpay_total,
              }
            );

            if (verifyResponse.status === 200) {
              // alert("Payment initiated. Waiting for confirmation.");
              handleSubmit(response.razorpay_payment_id);
            } else {
              console.error("Server error:", verifyResponse.statusText);
              alert("Failed to confirm payment with server.");
            }
          } catch (error) {
            console.error("Error verifying payment:", error);
            alert("Error verifying payment on the server.");
          }
        },
        theme: {
          color: "#000000",
        },
      };

      const rzpay = new Razorpay(options);

      rzpay.on("payment.failed", (response: any) => {
        console.error("Payment failed:", response.error);
        alert(`Payment failed: ${response.error.description}`);
      });

      rzpay.open();
    } catch (error) {
      console.error("Error during payment initiation:", error);
      alert("There was an error initiating the payment. Please try again.");
    }
  }, [formState, razorpay_total, Razorpay]);

  useEffect(() => {
    if (step !== 2) return;

    const savedEndTime = localStorage.getItem("discountTime");
    if (savedEndTime) {
      const endTime = new Date(savedEndTime).getTime();
      const now = new Date().getTime();
      const remainingTime = (endTime - now) / 1000;
      if (remainingTime > 0) {
        setTimeLeft(remainingTime);
      } else {
        localStorage.removeItem("discountTime");
        setTotalPrice(2999); // Update price when time runs out
        setStep(3);
      }
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          localStorage.removeItem("discountTime");
          setTotalPrice(2999); // Update price when time runs out
          setStep(3);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [step]);

  useEffect(() => {
    if (step === 2 && timeLeft > 0) {
      const endTime = new Date().getTime() + timeLeft * 1000;
      localStorage.setItem("discountTime", new Date(endTime).toISOString());
    }
  }, [timeLeft, step]);

  return (
    <div className="flex flex-col items-center py-10 my-10 relative">
      <div className="absolute inset-0 bg-dawn bg-cover bg-center bg-no-repeat z-0"></div>

      <p className="text-dark text-3xl md:text-[40px] font-medium font-made-mirage text-center">
        Enroll Now & Begin Your{" "}
        <i className="text-gold font-gt-super">Journey!</i>
      </p>
      <p className="text-purple text-sm md:text-xl mt-4 md:w-[600px] text-center">
        Fill out this form to help us understand your goals. Our team will reach
        out to you for further guidance and next steps.
      </p>

      <form className="flex flex-col items-center p-5 md:p-10 bg-[#A18DA31A] border border-[#5A495B80] opacity-100 rounded-3xl mt-10 z-10 w-full md:w-[640px]">
        {step === 1 ? (
          <>
            <p className="text-2xl font-made-mirage font-medium text-center">
              Register now at just ₹999
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 mt-12 w-full">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Full Name" theme={customLabel} />
                  <span className="text-gold">*</span>
                </div>
                <TextInput
                  id="name"
                  type="text"
                  value={formState.full_name}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      full_name: e.target.value,
                    }))
                  }
                  placeholder="John Doe"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="phno"
                    value="Phone Number"
                    theme={customLabel}
                  />
                  <span className="text-gold">*</span>
                </div>
                <TextInput
                  id="phno"
                  type="text"
                  value={formState.phone_number}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      phone_number: e.target.value,
                    }))
                  }
                  placeholder="9999999999"
                  addon="+91"
                  maxLength={10}
                />
              </div>
            </div>

            <div className="w-full mt-3 md:mt-6">
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" theme={customLabel} />
                <span className="text-gold">*</span>
              </div>
              <TextInput
                id="email"
                type="text"
                value={formState.email}
                onChange={(e) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    email: e.target.value.toLocaleLowerCase(),
                  }))
                }
                placeholder="example@gmail.com"
              />
            </div>

            <div className="self-start w-full mt-3 md:mt-6">
              <div className="mb-2 block">
                <Label
                  htmlFor="why_wedding_planner"
                  value="Why do you want to be a wedding planner?"
                  theme={customLabel}
                />
                <span className="text-gold">*</span>
              </div>
              <Textarea
                id="why_wedding_planner"
                value={formState.why_wedding_planner}
                onChange={(e) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    why_wedding_planner: e.target.value,
                  }))
                }
                placeholder="Share your motivation and what inspires you to become a wedding planner"
                rows={4}
                className="resize-none"
              />
            </div>
          </>
        ) : step === 2 ? (
          <div className="flex flex-col items-center mt-10 md:mt-12">
            <p className="text-2xl md:text-3xl font-made-mirage font-medium mb-2">
              Congratulations!
            </p>
            <p className="text-base md:text-xl text-purple max-w-[560px] text-center mb-9">
              You have unlocked an{" "}
              <span className="text-gold">exclusive discount.</span> Lock in
              this amazing deal by making a secure payment.
            </p>
            <div className="relative inline-block mb-2">
              <div className="relative inline-block">
                <p className="text-xl text-purple md:text-3xl font-made-mirage font-medium">
                  Rs.2999
                </p>
                <div className="absolute inset-0 w-full h-full">
                  <div className="diagonal-line"></div>
                </div>
              </div>
            </div>
            <p className="text-3xl text-gold md:text-5xl font-made-mirage font-medium mb-4 animate-bounce">
              Rs.999
            </p>
            <div className="border border-purple rounded-full px-4 py-2 md:py-3 text-center w-60">
              <p className="text-sm md:text-base">
                Discount expires in{" "}
                <span className="font-semibold">{formatTime(timeLeft)}</span>
              </p>
            </div>
            <div className="flex flex-row gap-3 md:gap-6 mt-10 mb-6">
              <div className="flex items-center">
                <Shield />
                <p className="ml-2 text-xs md:text-base">100% secure payment</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <CheckCircle />
            <p className="text-2xl md:text-3xl font-made-mirage font-medium mt-6">
              Registration successful!
            </p>
            <p className="md:text-xl text-purple mt-3 text-center">
              We will contact you via email with the next steps and share the
              Zoom link for the class scheduled on April 2nd.
            </p>
          </div>
        )}

        {step === 1 ? (
          <Button
            className="relative bg-black hover:!bg-gray-800 focus:ring-0 mt-8 group"
            onClick={() => {
              if (!validateForm()) {
                return;
              }
              setStep(2);
            }}
          >
            {loading ? (
              <>
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                  ></path>
                </svg>
                Submiting...
              </>
            ) : (
              <>
                Proceed
                <span className="ml-1 inline-block transition-transform duration-300 transform group-hover:translate-x-1">{`->`}</span>
              </>
            )}
          </Button>
        ) : step === 2 ? (
          <div className="flex flex-col items-center">
            <Button
              className="bg-black hover:!bg-gray-800 focus:ring-0"
              type="button"
              onClick={handlePayment}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-2 text-white inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                    ></path>
                  </svg>
                  Confirming Payment...
                </>
              ) : (
                <p className="md:text-lg">Pay now and claim discount</p>
              )}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default ClassForm;
