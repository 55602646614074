import React, { useState } from "react";
import { toast } from "react-toastify";
import { emailRegex } from "../lib/constants";
import { apiClient } from "../lib/apiClient";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { customLabel } from "../assets/styles/form";
import { CheckCircle } from "../assets/icons/Icons";

const ClassFormOne = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    full_name: "",
    phone_number: "",
    email: "",
    why_wedding_planner: "",
  });

  const resetState = () => {
    setFormState({
      full_name: "",
      phone_number: "",
      email: "",
      why_wedding_planner: "",
    });
  };

  const validateForm = () => {
    const { full_name, phone_number, email, why_wedding_planner } = formState;

    if (!full_name) {
      toast.error("Full name is required");
      return false;
    }
    if (!phone_number) {
      toast.error("Phone number is required");
      return false;
    }
    if (!email) {
      toast.error("Email is required");
      return false;
    }
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return false;
    }
    if (!why_wedding_planner) {
      toast.error("Please answer the question");
      return false;
    }
    if (why_wedding_planner.trim().split(" ").length < 5) {
      toast.error("Please provide a complete answer with at least five words.");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setLoading(true);

        const payload = {
          ...formState,
          amount: 999,
          date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
          class_type: "2ndApril_2h",
        };
        console.log(payload);

        const res = await apiClient.post(`/open/academy-registration`, payload);

        if (res?.status === 201) {
          toast.success("Registered successfully!");
          resetState();
          setStep(2);
        } else {
          console.log(res);
          toast.error("Unknown error occurred!");
        }
      } catch (backendError) {
        console.error("Backend Submission Error:", backendError);
        toast.error("Failed to submit the form. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col items-center py-10 my-10 relative">
      <p className="text-dark text-3xl md:text-[40px] font-medium font-made-mirage text-center">
        Enroll Now & Begin Your{" "}
        <i className="text-gold font-gt-super">Journey!</i>
      </p>
      <p className="text-purple text-sm md:text-xl mt-4 md:w-[600px] text-center">
        Fill out this form to help us understand your goals. Our team will reach
        out to you for further guidance and next steps.
      </p>

      <form className="flex flex-col items-center p-5 md:p-10 bg-[#A18DA31A] border border-[#5A495B80] opacity-100 rounded-3xl mt-10 z-10 w-full md:w-[640px]">
        {step === 1 ? (
          <>
            <p className="text-2xl font-made-mirage font-medium text-center">
              Register now at just ₹999
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 mt-12 w-full">
              <div>
                <Label htmlFor="name" value="Full Name" theme={customLabel} />
                <TextInput
                  id="name"
                  type="text"
                  value={formState.full_name}
                  onChange={(e) =>
                    setFormState({ ...formState, full_name: e.target.value })
                  }
                  placeholder="John Doe"
                />
              </div>

              <div>
                <Label
                  htmlFor="phno"
                  value="Phone Number"
                  theme={customLabel}
                />
                <TextInput
                  id="phno"
                  type="text"
                  value={formState.phone_number}
                  onChange={(e) =>
                    setFormState({ ...formState, phone_number: e.target.value })
                  }
                  placeholder="9999999999"
                  addon="+91"
                  maxLength={10}
                />
              </div>
            </div>

            <div className="w-full mt-3 md:mt-6">
              <Label htmlFor="email" value="Email" theme={customLabel} />
              <TextInput
                id="email"
                type="text"
                value={formState.email}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    email: e.target.value.toLowerCase(),
                  })
                }
                placeholder="example@gmail.com"
              />
            </div>

            <div className="self-start w-full mt-3 md:mt-6">
              <Label
                htmlFor="why_wedding_planner"
                value="Why do you want to be a wedding planner?"
                theme={customLabel}
              />
              <Textarea
                id="why_wedding_planner"
                value={formState.why_wedding_planner}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    why_wedding_planner: e.target.value,
                  })
                }
                placeholder="Share your motivation and what inspires you to become a wedding planner"
                rows={4}
                className="resize-none"
              />
            </div>

            <Button
              className="bg-black hover:!bg-gray-800 focus:ring-0 mt-8"
              onClick={handleSubmit}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </>
        ) : (
          <div className="flex flex-col items-center">
            <CheckCircle />
            <p className="text-2xl md:text-3xl font-made-mirage font-medium mt-6">
              Registration successful!
            </p>
            <p className="md:text-xl text-purple mt-3 text-center">
              We will contact you via email with the next steps and share the
              Zoom link for the class scheduled on April 2nd. A payment link
              will be sent later to confirm your registration.
            </p>
          </div>
        )}
      </form>
    </div>
  );
};

export default ClassFormOne;
