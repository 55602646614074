import React, { useEffect, useRef, useState } from "react";
import { DotsGrid } from "../../assets/icons/Icons";
import { Carousel } from "flowbite-react";

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

const WeddingVideos = () => {
  const [playing, setPlaying] = useState(false);
  const videoRefs = useRef<(HTMLIFrameElement | null)[]>([]);
  const carouselRef = useRef<HTMLDivElement | null>(null); // Fix for missing carouselRef

  // Load YouTube Iframe API script
  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    if (firstScriptTag) {
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
    }

    // Declare the onYouTubeIframeAPIReady function on the window object
    window.onYouTubeIframeAPIReady = () => {
      videoRefs.current.forEach((iframe, index) => {
        if (iframe) {
          new window.YT.Player(iframe, {
            events: {
              onStateChange: (event: any) =>
                handlePlayerStateChange(event, index),
            },
          });
        }
      });
    };
  }, []);

  const handlePlayerStateChange = (event: any, index: number) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      setPlaying(true);
    } else if (
      event.data === window.YT.PlayerState.PAUSED ||
      event.data === window.YT.PlayerState.ENDED
    ) {
      setPlaying(false);
    }
  };

  return (
    <section
      id="videos"
      className="flex flex-col items-center relative py-20 lg:p-20 px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="absolute left-0 top-20 hidden md:flex">
        <DotsGrid />
      </div>
      <div className="absolute right-0 top-20 hidden md:flex">
        <DotsGrid />
      </div>
      <div className="flex flex-col items-center space-y-10">
        <div className="text-center">
          <h3 className="text-dark text-3xl lg:text-[40px] font-medium font-made-mirage">
            Explore Our <i className="text-gold font-gt-super">Wedding </i>
            Styles!
          </h3>
          <p className="text-purple text-sm lg:text-xl mt-3 md:w-[500px] lg:w-[600px]">
            Discover the perfect wedding style for your big day with Zzeeh
            Weddings! Watch our exclusive wedding samples below.
          </p>
        </div>
      </div>

      <div
        className="h-56 md:h-96 xl:h-[600px] mt-10 w-[90%] xl:w-[80%]"
        ref={carouselRef}
      >
        <Carousel slide={!playing}>
          <iframe
            ref={(el) => (videoRefs.current[0] = el)}
            className="w-full h-56 md:h-96 xl:h-[600px]"
            src="https://www.youtube.com/embed/uYj5dgb3_ok?enablejsapi=1" // enable JS API
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            ref={(el) => (videoRefs.current[1] = el)}
            className="w-full h-56 md:h-96 xl:h-[600px]"
            src="https://www.youtube.com/embed/cbpIH_CkykM?enablejsapi=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            ref={(el) => (videoRefs.current[2] = el)}
            className="w-full h-56 md:h-96 xl:h-[600px]"
            src="https://www.youtube.com/embed/jV3iKj4ilNs?enablejsapi=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            ref={(el) => (videoRefs.current[3] = el)}
            className="w-full h-56 md:h-96 xl:h-[600px]"
            src="https://www.youtube.com/embed/_zWxJi6Ie5Y?enablejsapi=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Carousel>
      </div>

      <div className="flex flex-col space-y-3 mt-10 w-[90%] xl:w-[80%]">
        <p className="text-purple text-sm lg:text-xl text-center">
          At Zzeeh Weddings, we specialize in complete wedding planning,
          covering everything from venue selection, wedding décor, catering,
          photography, and even destination weddings. We turn your dream wedding
          into reality while ensuring your personal style shines.
        </p>
        <p className="text-purple text-sm lg:text-xl text-center">
          Planning a wedding can be overwhelming, but as your trusted wedding
          planner, we help you stay within budget while managing every detail
          seamlessly. From guest arrivals, accommodation, transportation, and
          more, our expert event coordination team ensures a flawless experience
          for you and your guests.
        </p>
        <p className="text-purple text-sm lg:text-xl text-center">
          Let Zzeeh Weddings create a memorable and stress-free wedding for you!
        </p>
      </div>
    </section>
  );
};

export default WeddingVideos;
