export const getMinEndDate = (date: string) => {
  const currentDate = new Date();
  const startDate = date ? new Date(date) : currentDate;
  return currentDate < startDate
    ? startDate.toISOString().split("T")[0]
    : currentDate.toISOString().split("T")[0];
};

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
};

export function discountPrice(currencyString: string) {
  // Extract the numeric part
  const num = Number(currencyString.replace(/[^\d]/g, ""));

  // Perform the adjustment
  const adjustedNum = num - 50000;

  // Convert the adjusted number back to string with the same format
  const formattedNum = adjustedNum.toLocaleString("en-IN");

  return `₹ ${formattedNum}/-`;
}
