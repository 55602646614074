import React from "react";
import TeamCard from "../ui/TeamCard";
import { team } from "../../assets/data/team";

const Team = () => {
  return (
    <section
      id="about_us"
      className="py-12 bg-[#f6f6f6] px-4 md:px-12 lg:px-16 xl:mx-auto max-w-[1920px] w-full"
    >
      <div className="flex flex-col items-center">
        <p className="text-sm font-semibold uppercase text-gold">Our Team</p>
        <p className="text-dark text-3xl md:text-[40px] font-medium font-made-mirage text-center mt-4 mb-6">
          Meet our Team Members
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-5 gap-5">
          {team.map((member, index) => (
            <TeamCard key={index} member={member} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
